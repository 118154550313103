import React from 'react'
import gerimg1 from '../assets/newfolder/gerimg1.png'
import gerimg2 from '../assets/newfolder/gerimg2.png'
import gerimg3 from '../assets/newfolder/gerimg3.png'
import gerimg4 from '../assets/newfolder/gerimg4.png'
import gerimg5 from '../assets/newfolder/gerimg5.png'
import { Link } from 'react-router-dom'

export default function Gercomponent() {
  return (
    <>
      <div className="accordion" id="accordionExample">

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Map of Germany
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div style={{ textAlign: "center" }} className="accordion-body">
              <img src={"/map/germenymap.webp"} className='img-fluid' alt="map" />

            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              Why Study In Germany?
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h4 className='text-danger'>Study in Germany</h4>
              <ul>
                <li>Land of Ideas</li>
                <li>Germans Are Known for their
                  expertise in Machinery</li>
                <li>No tution fees at public
                  universities in germany</li>
                <li>Germany is ranked among the world’s top destinations
                  for international students</li>
                <li>Germany is home to many of the Fortune 500
                  companies like Adidas, Volkswagen, Audi AG, BMW,
                  Bentley Motors Limited, Škoda Auto, Siemens, etc.</li>
                <li>World class research Oriented and Technical
                  Universities.</li>
                <li>Study programs taught in English</li>
                <li>Schengen Visa.</li>
                <li>No tuition fees at public/research universities in Germany.</li>
                <li>Cost-efficient option for quality education due to minimal
                  tuition fees.</li>
                <li>Language learning opportunities - Many German universities
                  offer free German classes to their international students</li>
                <li>Germany, with its rich history and culture, is located in the
                  heart of Europe. Countries like The Netherlands, France,
                  Belgium, Poland, Austria, and Switzerland are just a train ride
                  or drive on the Autobahn away.</li>
                <li>Speaking German is almost always a prerequisite, especially for
                  entry-level jobs.</li>
                <li>Best for Engineers, Science, and Management programs. Most of
                  Germany’s exports are products made in the areas of electrical
                  engineering, mechatronics, heavy machinery, the automotive industry,
                  environmental technology, pharmaceuticals, and chemicals.
                  Consumers around the world recognize “Made in Germany” as a seal
                  of quality.</li>

                <li>Internationally recognized degrees</li>
                <li>Around 400 higher education institutions offer graduate and postgraduate programs.</li>
                <li>Jobs in tech/IT, and jobs at internationally oriented startups - particularly in startup
                  hubs like Hamburg or Berlin</li>
                <li>Hamburg is home to trade and media companies;</li>
                <li>Munich and Stuttgart are strong in automotive and manufacturing;</li>
                <li>Frankfurt is the leading financial capital.</li>
                <li>Frankfurt and Munich, are among the world’s largest Airports, together serving
                  several hundred connections in Europe and the world.</li>
                <li>Germans are punctual. They are also famous for work and dedication.</li>
                <li>Students under 30 years age are eligible for public health insurnace whereas if they
                  are 30 years or older at the beginning of their studies, they need to get private
                  Insurance.</li>
              </ul>

            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              GERMANY AT A GLANCE
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h4 className='text-danger text-center'>GERMANY AT A GLANCE</h4>
              <p></p>
              <div className="card mb-3" >
                <div className="row g-0">
                  <div className="col-md-4">
                    <img src="/img/germany-glace.jpg" className="img-fluid rounded-start" alt="img" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">GERMANY AT A GLANCE</h5>
                      <p className="card-text">Germany is located at the heart of Europe, bordering
                        nine other countries. Clockwise from the North,
                        are Denmark, Poland, CZ, Austria, Switzerland,
                        France, Luxemburg, Belgium, and the Netherlands. It
                        makes Germany an ideal destination if you're eager to
                        explore other parts of Europe, as well. Climate
                        throughout the country with warm summers (22 ° C
                        to 35 ° C and cold winters (3° C to -10 ° C) and rain
                        falls throughout the year.</p>
                      {/* <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> */}
                    </div>
                  </div>
                </div>
              </div>

              <h4>Why Germany?</h4>
              <ul>
                <li>Germany consists of 16 States & the capital and largest city is Berlin.</li>
                <li>Euro is the official currency of Germany. (1 EUR = INR 80)</li>
                <li>Adolf Hitler was an Austrian-born German politician who was the dictator of Germany from
                  1933 until his death in 1945.</li>
                <li>Here are the best and popular cities in Germany for international students:
                  <ul>
                    <li style={{ listStyle: "decimal" }}>Berlin</li>
                    <li style={{ listStyle: "decimal" }}>Hamburg</li>
                    <li style={{ listStyle: "decimal" }}>Munich</li>
                    <li style={{ listStyle: "decimal" }}>Frankfurt</li>
                    <li style={{ listStyle: "decimal" }}>Aachen</li>
                    <li style={{ listStyle: "decimal" }}>Dresden</li>
                    <li style={{ listStyle: "decimal" }}>Stuttgart</li>
                    <li style={{ listStyle: "decimal" }}>Heidelberg</li>
                    <li style={{ listStyle: "decimal" }}>Cologne</li>
                    <li style={{ listStyle: "decimal" }}>Leipzig</li>
                  </ul>
                </li>
              </ul>

              <p><strong>Berlin –</strong>Capital of Germany and Germany’s largest and Europe’s second-largest city. Quite
                affordable for international students and best for IT / Tech jobs. The three largest
                universities are the Freie University of Berlin, the Humboldt University of Berlin, and the
                Technical University of Berlin.</p>
              <p><strong>Hamburg –</strong>The second-largest city in Germany after Berlin and has a strong economy. the
                city is home to the prestigious TUHH (Technische Universität Hamburg)It is known for IT /
                Tech jobs. Basically, whatever your field of interest is, be it social sciences, art, music,
                engineering, or design, you can find a degree program in Hamburg.</p>
              <p><strong>Munich –</strong>3rd largest city in Germany after Berlin & Hamburg. Close to the Austria border.
                Famous for Football. Home to two of the best universities in Germany - LMU Munich
                (Ludwig-Maximilians-Universität) and Technical University of Munich (TUM). Munich has also
                often been ranked among the world’s top 10 cities for quality of life. But it comes at a price:
                The city is the most expensive in the country when it comes to rent and food.</p>
              <p><strong>Frankfurt –</strong>The 5th largest city in Germany and the Financial capital city.
                Most expensive city. Goethe University Frankfurt was founded in 1914 and is one
                of the largest universities in Germany. Frankfurt School of Finance and
                Management offers specialized degrees that serve as a direct gateway into the
                industry. There, are a handful of Bachelor’s, Master’s, and MBA programs.</p>
              <p><strong>Heidelberg –</strong>Heidelberg has a very beautiful old town. Top tourist destination.
                Home of German oldest university name Heidelberg University famous for
                its medical faculty. German cancer research center is headquartered in
                Heidelberg. Affordable city in Germany.</p>
              <p><strong>Aachen –</strong>Affordable city. Known for innovation and technology city. Near to Germany’s
                borders with Belgium and the Netherlands. most renowned universities, The RWTH
                Aachen University, and FH Aachen located in Aachen</p>
              <p><strong>Stuttgart –</strong>is the strongest industrial region for Engineering. Two global companies
                whose headquarters are located in this city, are Porsche and Daimler (Mercedes-Benz
                Group). Home of top-notch universities – University of Hohenheim, University of
                Stuttgart, Stuttgart University of Applied Sciences, Stuttgart Media University, and many
                more.</p>
              <p><strong>Dresden -</strong> Dresden is the capital city of the German state of Saxony. Most vibrant, safe &
                affordable city. Dresden is home to excellent, world-class universities - TU Dresden
                University, The University of Applied Sciences Dresden. Many modern technology
                companies located in Dresden – Cloud&Heat Technologies, Infineon Technologies AG,
                Senorics, Cognitec IMA Dresden and many more</p>
              <p><strong>Leipzig -</strong> Leipzig is a large, dynamic city in the state of Saxony which hosts a number of
                international trade fairs. A wonderful destination for international students to live and study. The
                famous companies that operate in Leipzig, the likes as Amazon, DHL, BMW, and Porsche. The
                University of Leipzig was founded in 1409 and is one of Germany's leading universities when
                it comes to top-class research and medical expertise and Leipzig University of Applied
                Sciences (HTWK Leipzig) offers a combination of practically oriented teaching and
                application-oriented research.</p>
              <p><strong>Cologne (Köln) -</strong> is the largest city and the fourth largest in Germany. It is a popular study
                destination for international students in Germany due to its affordable living cost, the wide range
                of university choices, as well as an outstanding quality of life. Numerous universities offer English
                Taught courses – the University of Cologne, Cologne University of Applied Sciences (TH Köln),
                German Sport University Cologne, and Köln University of Applied Sciences</p>
            </div>
          </div>
        </div>


        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
              USP for Counselling
            </button>
          </h2>
          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h4 className='text-danger text-center'>USP for Counselling</h4>
              <ul>
                <li>Berlin has the largest train station in Europe.</li>
                <li>The oldest German university was founded in Heidelberg in 1386.</li>
                <li>The German economy is Europe’s strongest & largest national economy. Germany is the world’s Fourth-Largest.</li>
                <li>It is the European leader in terms of growth, employment, and
                  export.</li>
                <li>More than 83 million people live here – the most populous country
                  in the European Union.</li>
                <li>Part-time jobs allowed 20 hours per week. International students are
                  allowed to work for 120 full working days or 240 half working days
                  in a year.</li>
                <li>Typically the student wages range from 8 to 12 Euros an
                  hour. Students can work on campus or outside the
                  campus.</li>
                <li>Students stay back option for 18 months after
                  completion of the study program.</li>
                <li>Indefinite right of residence to graduates after 5 years of
                  working in Germany.</li>
                <li>The residency permit will also allow you to travel
                  visa-free in the whole Schengen Area and other
                  countries.</li>
                <li>Students with German language skills are entitled to
                  higher wages compared to the students having no skills.</li>
                <li>Germany is the seventh-largest country in Europe.</li>
                <li>German is the most widely taught third language across
                  the world.</li>
                <li>Germany, Switzerland, Austria, Luxembourg, and
                  Liechtenstein have German as the official language.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              ECTS in Germany / Europe? European Credit Transfer Systems
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h5 className=''>What is ECTS in Germany / Europe? European Credit Transfer Systems</h5>
              <ul>
                <li>The ECTS system lets you understand your
                  workload e.g. 30 ECTS – 6 months and 60
                  ECTS = 1 year</li>
                <li>First cycle programs (UG Degree) are
                  worth 180 - 210</li>
                <li>ECTS second cycle programs (Master) are
                  worth 60 - 120 ECTS; while third cycle
                  programs (PhD) don't have a specific
                  ECTS range.</li>
              </ul>
              <h5>What is the difference between Technical University (TU) and
                University of Applied Sciences (FH)?</h5>
              <p>Hochschule - Techincal Universities (TU) are the subcategory of
                universities. They are more research-oriented and provide theoretical
                knowledge based on the subjects related to a specific field also Technische
                Universitäten retain the right to grant doctorates</p>
              <p>Fachhochschule - On the other hand, Universities of Applied Sciences (FH)
                are totally focused on practical knowledge or industry-oriented studies and
                cannot provide doctorate degrees but students can do Ph.D. from any
                research universities after completing of master’s from these universities.</p>

              <h5>Example for one application semester</h5>
              <h5>University 1</h5>
              <ul>
                <li><strong>Desired course of study A</strong> 75.00 EUR</li>
                <li><strong>Desired course of study B</strong> 30.00 EUR</li>
              </ul>
              <h5>University 2</h5>
              <li><strong>Desired course of study C</strong> 30.00 EUR</li>
              <p><strong>Total </strong> 135.00 EUR</p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
              TU9 are the top nine German Technical Universities
            </button>
          </h2>
          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <h4 className='text-danger text-center'>TU9 are the top nine German Technical Universities</h4>
              <ul>
                <Link to={"https://blog.msingermany.co.in/tu9-universities-germany-guide-international-students"}><li>RWTH Aachen University</li></Link>
                <Link to={"https://blog.msingermany.co.in/tu9-universities-germany-guide-international-students#STEP2"}><li>TU Berlin</li></Link>
                <Link to={"https://blog.msingermany.co.in/tu9-universities-germany-guide-international-students#STEP3"}><li>TU München</li></Link>
                <Link to={"https://blog.msingermany.co.in/tu9-universities-germany-guide-international-students#STEP4"}><li>TU Braunschweig</li></Link>
                <Link to={"#"}><li>TU Darmstadt</li></Link>
                <Link to={"#"}><li>Karlsruhe Institute of Technology (KIT)</li></Link>
                <Link to={"#"}><li>Leibniz Universität Hannover</li></Link>
                <Link to={"#"}><li>TU Dresden</li></Link>
                <Link to={"#"}><li>Universität Stuttgart</li></Link>
              </ul>
              <h4 className='text-danger text-center'>Entry Requirements (Public Funded)</h4>
              <p>For Bachelor - English Taught</p>
              <ul>
                <li>80% or above +1 year University or JEE both exams certificates or Freshman Exam</li>
                <li>IB Diploma Certificate above 29</li>
                <li>IELTS 6 or 6.5</li>
              </ul>

              <h4>Or via Eurasia – Complete German Taught</h4>

              <ul>
                <li>65% in Class 12th (with or without 1 year University or JEE both exams certificates) or IB
                  Diploma Certificate above 29</li>
                <li>2 LORs</li>
                <li>SOP</li>
              </ul>
              <p><strong>Procedure –</strong> (9 months Language (TF 9,000 EUR if the student pays in one go) + 1-year
                foundation leading to Bachelor’s degree.)</p>
              <p><strong>Note-</strong> (95 % or above required for Medicine)</p>
              <h5>For Master</h5>
              <ul>

                <li>Above 75% to 80%</li>
                <li>IELTS 6.5 to 7</li>
                <li>3 years and 4 years are acceptable with 180 to 210
                  credits</li>
                <li>Internships, ECA, Work Exp</li>
                
              </ul>
              <img src='/img/grmn.png' className='text-center' alt='img' />
              <h5 className='text-danger'>Private University requirements for Bachelor and
                Master</h5>
              <ul>
                <li>Min 50% is acceptable (recommend University specific criteria)</li>
                <li>IELTS or TOEFL</li>
              </ul>
              <h5 className='text-danger'>About Private Universities</h5>
              <ul>
                <li>EURASIA</li>
                <li>GISMA Business School
                  (the University of Law and
                  Grenoble Ecole De
                  Management)</li>
                <li>Arden University</li>
                <li>BSBI</li>
                <li>SRH Berlin</li>
                <li>IU International University
                  of Applied Sciences</li>
                <li>Munich Business School</li>
                <li>Schiller International University</li>
                <li>Macromedia University of
                  Applied Sciences</li>
                <li>NIT collaboration with TUHH</li>
                <li>Cologne Business School</li>
                <li>EU Business School</li>
                <li>University of Applied Science
                  Europe</li>
                <li>ISM Frankfurt</li>
                <li>New European College</li>
              </ul>
              <h2 className='text-danger text-center'>Show Money</h2>
              <ul>
                <li style={{ listStyle: "circle" }}>German Blocked Account (Sperrkonto): Fintiba, Coracle, Expatrio Bank.</li>
                <li style={{ listStyle: "circle" }}>Note - the money deposited into the account cannot be withdrawn until the account holder arrives in Germany.</li>
                <Link to={"https://fintiba.com/blog/blocked-amount-increase-to-992-euro/"}><li style={{ listStyle: "circle" }}>https://fintiba.com/blog/blocked-amount-increase-to-992-euro/</li></Link>
                <Link to={"#"}><li style={{ listStyle: "circle" }}>https://www.remitx.com/blockedaccount/index.aspx</li></Link>
                <li style={{ listStyle: "circle" }}>Increase of blocked account as of fall semester 2022/2023. International students will be required to block a total amount of € 11,904 per year for their stay in Germany withdraw €992 per month for one year.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="section-head col-sm-12 mb-1 mt-3">
          <h4><span>Latest </span>Update</h4>
        </div>

        <div className="accordion-item border">
          <h2 className="accordion-header" id="headingSix">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
              APS
            </button>
          </h2>
          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <p>The APS is a joint institution of the German Embassy’s cultural department and the German Academic Exchange Service (DAAD). The APS checks the authenticity of educational biographies and the corresponding educational certificates, and issues certificates if the result is positive.</p>

              <p>It is mandatory for UG and PG both. An APS Certificate for Germany is proof of the authenticity of their Indian academic documents and is a prerequisite for your German Study visa application. The APS Certificate Germany is in place to simplify your application process to German universities and accelerate your student visa application.</p>
            </div>
          </div>
        </div>



      </div>
    </>
  )
}

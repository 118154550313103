import React from 'react'

export default function Module2() {
  return (
    <>
    <div className="accordion" id="accordionExample">
  {/* Tab 1 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Entry Criteria for Bachelors & UG Diplomas
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>:- Grades minimum 50‐55% in Std. 12th for UG Diplomas and Bachelors  Degree requires above 60% (exceptions are also there that may except 55 % as well), however high ranked universities requires  70% and above.</p>
        <p>:- Specific subject requirements like Mathematics in Std. 12th may also exist for some programs.</p>
      </div>
    </div>
  </div>

  {/* Tab 2 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Entry Criteria for Masters & PG Diplomas
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Masters –</h6>
        <ul>
            <li>Minimum 65% required in Bachelors where as most of the high ranked universities  require 70% and above.</li>
            <li>16 years of full time education required for Masters in technical streams like  engineering, pharmacy, computer science etc.</li>
            <li>15 years of full time education acceptable for MBA in some of the universities represent.</li>
        </ul>
        <h6>PG Diplomas –</h6>
        <ul>
            <li>Minimum 50‐55% in Bachelors.</li>
            <li>15 years of education accepted for all PG Diplomas in community colleges.</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 3 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      English Language Requirements
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li className='my-1'>For UG Diploma Programs, IELTS 6.0 with no band less than 6.0 is required  for majority of the programs and some colleges do accept one band 5.5.</li>
            <li className='my-1'>For Bachelor’s Degree, TOEFL iBT (only accepted by Universities) minimum  score of 79 or IELTS 6.5 with no band less than 6.0 is required.</li>
            <li className='my-1'>For PG Diploma Programs, TOEFL iBT (only accepted by Universities)  minimum score of 88 or IELTS 6.5 with no band less than 6.0 is required.  (Some universities may require higher scores)</li>
            <li className='my-1'>For Masters Programs, TOEFL iBT (only accepted by Universities) minimum  score of 88 or IELTS 6.5 with no band less than 6.0 is required. (Some  universities may require higher scores)</li>
            <li className='my-1'>You can also apply to universities and a few colleges in Alberta that accept Medium of Instruction letter.</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 4 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Intake
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>The major available intakes are January and September . Some popular programs are also offered at May intake as well.</p>
        <h6>Are there any Institutions accepting Distance Learning in Canada? </h6>
        <p>Distance Learning is acceptable in Canada by few Institutions as listed below: </p>
        <li className="my-1">Thompson Rivers University </li>
        <li className="my-1">Vancouver Island University</li>
        <li className="my-1">Conestoga College</li>
        <li className="my-1">Fanshawe College</li>
        <li className="my-1">Canadore College</li>
        <li className="my-1">St Clair College</li>
        <li className="my-1">George Brown College</li>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 5 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Broad Classification of Courses
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ol>
        <li className="my-1">Arts and Design </li>
        <li className="my-1">Architecture and Construction</li>
        <li className="my-1">Architecture and Construction</li>
        <li className="my-1">Business and Management </li>
        <li className="my-1">Computing</li>
        <li className="my-1">Education</li>
        <li className="my-1">Engineering</li>
        <li className="my-1">English Language and Linguistics </li>
        <li className="my-1">Hospitality, Events and Tourism Management </li>
        <li className="my-1">Health</li>
        <li className="my-1">Humanities</li>
        <li className="my-1">Journalism and Mass Communication</li>
        <li className="my-1">Law</li>
        <li className="my-1">Life Sciences </li>
        <li className="my-1">Physical Science</li>
        <li className="my-1">Pharmacy</li>
        <li className="my-1">Social and Behavior Science </li>
        <li className="my-1">Sports</li>
       </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}

  {/* Tab 6 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Architecture and Construction
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG :- Students from Science background are eligible to apply for Architecture and Construction courses. English language, art and design and mathematics are all preferred subjects to have studied before applying to mentioned programs, and student’s should also enjoy drawing freehand and have an interest in design and producing 3D work. </p>
        <ol>
            <li className="my-1">Diploma in Carpentry and Renovation Technician (Co-op) </li>
            <li className="my-1">Advanced Diploma in Construction Engineering Technology (Construction Management) </li>
            <li className="my-1">Honours Bachelor of Interior Design </li>
            <li className="my-1">Diploma in Landscape Technician (Co-op) </li>
            <li className="my-1">Advanced Diploma in Architectural Technology </li>
            <li className="my-1">Advanced Diploma in Building Renovation Technology </li>
            <li className="my-1">Advanced Diploma in Construction Engineering Technology </li>
            <li className="my-1">Diploma in GIS and Urban Planning </li>
            <li className="my-1">Diploma in Interior Decorating </li>
            <li className="my-1">Diploma in Building Inspection Technician </li>
            <li className="my-1">Diploma in Construction Engineering Technician </li>
            <li className="my-1">Diploma in Architectural Technician </li>
            <li className="my-1">Diploma in Renovation Construction Technician (Co-op) </li>
            <li className="my-1">Bachelor of Arts in Urban and Inner-City Studies.</li>
        </ol>
        <p>PG :- A relevant degree is needed to be eligible for Master’s and PG Diploma course. </p>
        <ol>
            <li className="my-1">Graduate Certificate in BIM and Integrated Practice </li>
            <li className="my-1">Graduate Certificate in Construction Project Management </li>
            <li className="my-1">Graduate Certificate in User Experience Design </li>
            <li className="my-1">Graduate Certificate in Environmental Building Sciences </li>
            <li className="my-1">Master of Community Planning </li>
            <li className="my-1">Post-Diploma Certificate in Kitchen and Bath Design </li>
            <li className="my-1">Graduate certificate in Building Information Modelling Management </li>
            <li className="my-1">Master of Applied Science in Building Engineering/Building Science </li>
        </ol>

      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 7 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Accounting, Finance and Economics
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG :- Maths is the most useful subject to have when you’re applying for an accounting degree but Economics, statistics, and business are all recommended subjects. Generally , students from Commerce background are preferred but science background students can also apply.</p>
        <ol>
            <li className="my-1">Bachelor of Commerce </li>
            <li className="my-1">Bachelor of Business Administration in Accounting </li>
            <li className="my-1">Diploma in Accounting </li>
            <li className="my-1">Diploma in Accounting and Finance </li>
            <li className="my-1">Bachelor of Arts in Economics and Accounting (Co-op) </li>
            <li className="my-1">Diploma in Business Administration</li>
            <li className="my-1">Associate of Arts in Accounting </li>
            <li className="my-1">Bachelor of Management – Finance </li>
            <li className="my-1">Diploma in Business – Finance </li>
            <li className="my-1">Advanced Diploma in Finance – Business Administration </li>
            <li className="my-1">Bachelor of Arts in Economics </li>
            <li className="my-1">Bachelor of Business Administration in Economics and Finance (Combined) </li>
            <li className="my-1">Associate of Arts in Economics </li>
            <li className="my-1">BSc Major in Financial Mathematics and Economics (Optional Co-op)</li>
            <li className="my-1">Bachelor of Science in Economics </li>
        </ol>
        <p>PG:- Usually, a second-class honours degree in a subject related to finance, accounting or business studies is required. Students from any quantitative backgrounds are also welcomed. </p>
        <ol>
            <li className="my-1">Master of Arts in Development Economics </li>
            <li className="my-1">Post Degree Diploma in Global Banking and Economics </li>
            <li className="my-1">Master of Business Administration </li>
            <li className="my-1">Post-Baccalaureate Diploma in Accounting and Finance </li>
            <li className="my-1">Post Degree Diploma in Business Administration (Accounting and Finance Major)</li>
            <li className="my-1">Post Degree Diploma in Accounting</li>
            <li className="my-1">Graduate Certificate in Professional Accounting </li>
            <li className="my-1">Master of Professional Accounting</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 8 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      Business and Management
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Grade 12 maths preferred and is compulsory in many colleges and universities. However, some colleges and universities will accept 10th Std Maths in lieu of the same.</p>
        <ul>
            <li className="my-1">Diploma in Business Administration </li>
            <li className="my-1">Diploma in General Business </li>
            <li className="my-1">Diploma in Commerce and Business Administration </li>
            <li className="my-1">Diploma in Business Management – Marketing Specialization </li>
            <li className="my-1">Diploma in Business Management – Leadership Specialization </li>
            <li className="my-1">Diploma in Business – Insurance </li>
            <li className="my-1">Diploma in Business Sales and Marketing </li>
            <li className="my-1">Diploma in Supply Chain and Operations – Business </li>
            <li className="my-1">Advanced Diploma in Business Administration – Human Resources </li>
            <li className="my-1">Bachelor of Business Administration Degree</li>
            <li className="my-1">Bachelors of Business Administration (BBA) – Management </li>
            <li className="my-1">Bachelors in Business Technology Management  (Mathematics Required)</li>
            <li className="my-1">Bachelor of Business Administration – Entrepreneurship (Co-op) </li>
            <li className="my-1">Bachelor of Arts in International Economics and Business </li>
        </ul>
        <p>PG:- Usually management courses accept degree qualification from any field .Work Experience is required for MBA in Top Ranked Universities. Some Universities also offers MBA without Work Experience. A keen interest shown in SOP bolsters the application</p>
        <ul>
            <li className="my-1">Post Baccalaureate Diploma in North American Business Management Applied </li>
            <li className="my-1">Post Degree Diploma in Business Administration – Global Business Management </li>
            <li className="my-1">Graduate Certificate in Sustainable Business Management </li>
            <li className="my-1">Graduate Certificate in Business Analytics – International</li>
            <li className="my-1">Graduate Certificate in Supply Chain Management</li>
            <li className="my-1">Graduate Certificate in International Business Management</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 9 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Toggle Title
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <img src="/img/resource-canada3.png" className='img-fluid my-2' alt="" /><br />
        <img src="/img/resource-canada4.png" className='img-fluid my-2' alt="" /><br />
        <img src="/img/resource-canada5.png" className='img-fluid my-2' alt="" /><br />
        <img src="/img/resource-canada6.png" className='img-fluid my-2' alt="" />
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 10 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Hospitality, Events and Tourism Management
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG: Entry requirements for such courses isn’t any specific and accepts students from all background provided the intent to study is well explained in SOP. </p>
        <ul>
            <li className="my-1">Associate of Arts in Hospitality and Tourism</li>
            <li className="my-1">Diploma in Hospitality </li>
            <li className="my-1">Diploma in Tourism – Operations Management</li>
            <li className="my-1">Diploma in Hospitality – Hotel and Restaurant Operations</li>
            <li className="my-1">Diploma in Food and Beverage Management – Hotel and Restaurant Operations (Optional Co-op)</li>
            <li className="my-1">Diploma in Tourism </li>
            <li className="my-1">Diploma in Tourism & Hospitality Management</li>
            <li className="my-1">Bachelor of Tourism Management </li>
            <li className="my-1">Bachelors of Tourism & Hospitality </li>
            <li className="my-1">Bachelor of Hospitality Management</li>
            <li className="my-1">Bachelors of Business Administration (BBA) – Tourism and Hospitality Management </li>
        </ul>
        <p>PG: A relevant bachelor’s degree is a must. </p>
        <ul>
            <li className="my-1">Graduate Certificate in Hotel, Resort and Restaurant Management </li>
            <li className="my-1">Graduate Certificate in Hospitality and Tourism Management</li>
            <li className="my-1">Post Degree Diploma in Hospitality Marketing</li>
            <li className="my-1">Graduate Certificate in Food Tourism.</li>
            <li className="my-1">Master of Arts in Tourism Management.</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}

  {/* Tab 11 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Computing
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Students from science background in 12th can apply to computing programmes. Maths will be a mandatory subject for these courses. </p>
        <p>PG:- Students from Bachelors of Computer Science, B.Tech,BCA,Bsc Computer sc. can apply for these courses.</p>
        <div className="row mx-0">
            <div className="col-md-6">
            <h6>List of UG Courses:-</h6>
                <ol>
                    <li className="my-1">Bachelor of Computer Science</li>
                    <li className="my-1">Bachelor of Computing and Financial Management</li>
                    <li className="my-1">Bachelor of Mathematics in information Technology Management</li>
                    <li className="my-1">Bachelor of Data Science</li>
                    <li className="my-1">Bachelor of Technology in Information Technology</li>
                    <li className="my-1">Bachelors of Engineering – Computer Engineering</li>
                    <li className="my-1">Bachelor of Science in Game Programming</li>
                    <li className="my-1">Bachelor of Computing in Software Engineering</li>
                    <li className="my-1">Bachelor of Science in Analytics – Data Analytics</li>
                    <li className="my-1">Diploma in Web and Graphic Design</li>
                    <li className="my-1">Diploma in Computer Systems Technician- Networking</li>
                    <li className="my-1">Advanced Diploma in Computer Systems Technology- Networking (Optional Co-op)</li>
                    <li className="my-1">Advanced Diploma in Computer Programming and Analysis (Co-Op )</li>
                    <li className="my-1">Diploma in Computer Engineering Technician</li>
                    <li className="my-1">Diploma in 3D Animation for Film and Games</li>
                    <li className="my-1">Diploma in Cyber Defence and Cloud Administration</li>
                    <li className="my-1">Diploma in Game Design</li>
                    <li className="my-1">Diploma in Interactive Media Design – Web</li>
                    <li className="my-1">Diploma in Computer Security Analyst</li>
                    <li className="my-1">Diploma in Digital Arts </li>
                </ol>
            </div>
            <div className="col-md-6">
                <h6>List of PG Courses:-</h6>
                <ol>
                    <li className="my-1">Post-Baccalaureate Diploma in Computer and Information Systems</li>
                    <li className="my-1">Post Diploma in Information Technologies</li>
                    <li className="my-1">Post-Degree Diploma in Data Analytics</li>
                    <li className="my-1">Graduate Certificate in Mobile Application Development AND Information Systems Business Analysis</li>
                    <li className="my-1">Post Graduate Certificate in Cyber Security + Entrepreneurship ( Bundled Program )</li>
                    <li className="my-1">Post Graduate Certificate in Artificial Intelligence and Data Analytics</li>
                    <li className="my-1">Master of Science in Big Data Analytics: Applied Modelling and Quantitative Methods</li>
                    <li className="my-1">Graduate Certificate in Software and Information Systems Testing</li>
                    <li className="my-1">Post Graduate Certificate in Cyber Security</li>
                    <li className="my-1">Graduate Certificate in Cloud Computing</li>
                    <li className="my-1">Graduate Certificate in Network Architecture and Security Analytics</li>
                    <li className="my-1">Graduate Certificate in Software Quality Assurance and Test Engineering (Optional Co-op)</li>
                    <li className="my-1">Graduate Certificate in Research Analyst</li>
                    <li className="my-1">Graduate Certificate in Database Application Developer</li>
                    <li className="my-1">Graduate certificate in Project Management – Information Technolo</li>
                    <li className="my-1">Graduate certificate in Project Management</li>
                    <li className="my-1">Graduate certificate in Wireless Networking</li>
                    <li className="my-1">Master of Science in Computer Science ( Course based )</li>
                </ol>
            </div>
        </div>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 12 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
      Education
      </button>
    </h2>
    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Specific subjects in 12th are not generally required, unless taking a subject based degree (i.e., education with English may require an 80% in English). Student’s will also need at least five subjects in 10th whose grades should range from (A – C) including English and Maths.</p>
        <p>PG:- Usually , an honors degree in an education background is preferred.</p>
        <h6>List of Courses :-</h6>
        <ol>
            <li className="my-1">Bachelor of Education</li>
            <li className="my-1">Bachelor of Early Childhood Care and Education </li>
            <li className="my-1">Diploma in Educational Support</li>
            <li className="my-1">BA in Physical Education Teaching </li>
            <li className="my-1">Bachelor of Physical Education and Coaching</li>
            <li className="my-1">Bachelor of Arts in International Education Studies (Co-op)</li>
            <li className="my-1">Diploma in Early Childhood Education </li>
            <li className="my-1">Masters of Education (TESL) </li>
            <li className="my-1">Masters of Education in Curriculum and Instruction</li>
            <li className="my-1">Master of Education in Education Leadership </li>
            <li className="my-1">Master of Education in Special Education</li>
            <li className="my-1">Bachelor of Education – Sociology</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 13 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
      Engineering
      </button>
    </h2>
    <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <div className="row mx-0">
            <div className="col-md-6">
            <p>UG:- Usually, 60% or more is required in Maths and Physics in 12th Std.</p>
                <ol>
                    <li>Diploma in Civil Engineering Technology</li>
                    <li>Diploma in Engineering and Fabrication Technologies</li>
                    <li>Diploma in Design and Manufacturing Engineering Technology</li>
                    <li>Advanced Diploma in Mechanical Engineering Technician(Co-op)</li>
                    <li>Bachelor of Chemical and Biochemical Engineering – Green Process Engineering ( B. Green Process Engineering/HBA)</li>
                    <li>Bachelor of Electrical and Computer Engineering – Computer Engineering Program( A. Electronic Devices for Ubiquitous Computing)</li>
                    <li>Bachelor of Engineering (Honours) in Electrical Engineering</li>
                    <li>Diploma in Construction Engineering Technician</li>
                    <li>Advanced Diploma in Welding Engineering Technology</li>
                    <li>Diploma in Energy Systems Engineering Technician</li>
                    <li>Bachelor of Science in Engineering- Geological Engineering</li>
                    <li>Diploma in Mining Engineering Technician</li>
                    <li>Diploma in Packaging Engineering Technician (Optional Co-op)</li>
                    <li>Diploma in Chemical Engineering</li>
                </ol>
            </div>
            <div className="col-md-6">
                <p>PG:- A degree in relevant subject is must.</p>
                <ol>
                    <li className="my-1">Master of Science in Electronic Systems Engineering</li>
                    <li className="my-1">Master of Science in Environmental System Engineering</li>
                    <li className="my-1">Master of Engineering in Industrial System Engineering</li>
                    <li className="my-1">Master of Engineering in Process System Engineering </li>
                    <li className="my-1">Master of Science in Software Systems Engineering</li>
                    <li className="my-1">Master of Applied Science in Oil & Gas Engineering</li>
                    <li className="my-1">Graduate Certificate in Quality Engineering Management (Co-op)</li>
                    <li className="my-1">Master of Science in Petroleum System Engineering</li>
                    <li className="my-1">Master of Science in Engineering in Chemical Engineering (MSc Eng)</li>
                    <li className="my-1">Master of Engineering in Internetworking</li>
                </ol>
            </div>
        </div>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}

  {/* Tab 14 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
      Various Engineering fields are:
      </button>
    </h2>
    <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li className="my-1">Computer science Engineering</li>
            <li className="my-1">Industrial Engineering</li>
            <li className="my-1">Architectural Engineering</li>
            <li className="my-1">Mechanical Engineering</li>
            <li className="my-1">General engineering and technology</li>
            <li className="my-1">Aerospace engineering</li>
            <li className="my-1">Civil engineering</li>
            <li className="my-1">Electrical engineering</li>
            <li className="my-1">Biomedical engineering</li>
            <li className="my-1">Chemical engineering</li>
            <li className="my-1">Quality control</li>
        </ul>
        <h5>Some good universities for popular universities are:</h5>
        <div className="row mx-0">
            <div className="col-md-4">
                <h6>ENGINEERING</h6>
                <ul>
                    <li>University of Waterloo</li>
                    <li>Queen’s University</li>
                    <li>Toronto Metropolitan University</li>
                    <li>University of New Brunswick</li>
                    <li>Lakehead University</li>
                    <li>University of Regina</li>
                </ul>
            </div>

            <div className="col-md-4">
                <h6>COMPUTER SCIENCE</h6>
                <ul>
                    <li>Queen’s University</li>
                    <li>University of Waterloo</li>
                    <li>University of Guelph</li>
                    <li>Wilfrid Laurier University</li>
                    <li>UNBC</li>
                    <li>Acadia University</li>
                </ul>
            </div>

            <div className="col-md-4">
                <h6>DATA SCIENCE</h6>
                <ul>
                    <li>Western University</li>
                    <li>Simon Fraser University</li>
                    <li>University of Manitoba</li>
                    <li>Wilfrid Laurier University</li>
                    <li>Trent University</li>
                    <li>University of New Brunswick</li>
                </ul>
            </div>

        </div>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 15 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
      Health
      </button>
    </h2>
    <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Students from Science background are eligible to apply for these courses. </p>
        <p>PG:- Generally , an upper-class degree in Health-related subjects such as Nursing, General Nursing and Midwifery, Physiotherapy etc. is required to be eligible for PG Diploma and Masters course. Some courses like PG diploma in Nursing might require some prior Work Experience.</p>
        <h6>List of Courses for Grade 12 (PCBM) – UG:-</h6>
        <ol>
            <li>BSc Nursing</li>
            <li>Bachelor of Science in Health Studies</li>
            <li>Bachelor of Science in Kinesiology – Honor’s</li>
            <li>Bachelor of Science in Medical Professional Stream</li>
            <li>Bachelor of Science in Psychology – Honor’s</li>
            <li>BSc (Hons) Nutrition and Dietetics , BSc Genetics</li>
            <li>Bachelor of Pathology and Laboratory Medicine (Major in Pathology)</li>
            <li>Bachelor of Health Science – Radiograph</li>
            <li>Bachelor of Health – Biomedical Sciences and Health</li>
            <li>Bachelor of Science in Cellular, Physiological and Pharmacological Sciences</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 16 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
      English Language and Linguistics
      </button>
    </h2>
    <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <div className="row mx-0">
            <div className="col-md-6">
                <p>UG:- Generally Arts and Humanities students apply for these courses. However, students from science and commerce background with good English marks can also apply.</p>
                <ol>
                    <li>BA English</li>
                    <li>BA English Language and Literature</li>
                    <li>BA(Hons) English Literature and Linguistics</li>
                    <li>Bachelor in English and Creative Writing</li>
                    <li>BA (Hons) English in Education</li>
                    <li>BA (Hons) Comparative Literature and Culture and English</li>
                    <li>BA (Hons) Music and English</li>
                </ol>
            </div>
            <div className="col-md-6">
                <p>PG:- A minimum of a second-class Honours degree is preferred . Applicants without these formal qualifications but with significant appropriate/relevant work/life experience can apply.</p>
                <ol>
                    <li>MA English Literature</li>
                    <li>MA English Studies</li>
                    <li>MA English Language Teaching</li>
                    <li>Graduate Certificate in Teaching English as a Second Language</li>
                    <li>Master of Arts in English (MA) with Specialization in Women’s Studies</li>
                </ol>
            </div>
        </div>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}

  {/* Tab 17 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
      Humanities
      </button>
    </h2>
    <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Most of the Universities will require students having studied specific humanities subject. In addition, student’s will also need minimum C grade in 5 subjects in std 10th . </p>
        <p>PG:- Applicant should have a degree in relevant discipline</p>
        <h6>List of Courses:-</h6>
        <ol>
            <li className="my-1">Bachelor of Arts – Philosophy</li>
            <li className="my-1">Bachelor of Arts – Communication Studies</li>
            <li className="my-1">Bachelor of Arts in Arts History</li>
            <li className="my-1">Master of Arts – Women & Gender Studies</li>
            <li className="my-1">Bachelor of Arts – Sociology & Anthropology</li>
            <li className="my-1">Bachelor of Arts in International Politics</li>
            <li className="my-1">Bachelor of Arts in Sociology</li>
            <li className="my-1">Master of Arts – Communication</li>
            <li className="my-1">Bachelor of Arts in Political Science</li>
            <li className="my-1">Bachelor of Arts in Youth and Children’s Studies</li>
            <li className="my-1">Bachelor of Arts in Cultural Studies (Co-op)</li>
            <li className="my-1">Bachelor of Arts in Women and Gender Studies</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 18 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
      Journalism and Mass Communication
      </button>
    </h2>
    <div id="collapseEighteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Students from any background can apply to these courses . A strong SOP bolsters the application. </p>
        <p>PG:- A minimum of a second-class relevant degree is preferred . Applicants without these formal qualifications but with significant appropriate/relevant work/life experience can apply.</p>
        <h6>List of Courses :-</h6>
        <ol>
            <li className="my-1">Graduate Certificate in Broadcast Journalism – Television News</li>
            <li className="my-1">Diploma in Journalism – Broadcast</li>
            <li className="my-1">Bachelor of Journalism</li>
            <li className="my-1">Bachelor of Arts in Digital Media and Journalism</li>
            <li className="my-1">Bachelor of Arts in Journalism and Creative Writing</li>
            <li className="my-1">Graduate Certificate in Contemporary Journalism</li>
            <li className="my-1">Bachelor of Communication Studies</li>
            <li className="my-1">Diploma in Broadcast and Online Journalism</li>
            <li className="my-1">Post Degree Diploma in Professional Communication</li>
            <li className="my-1">Bachelor of Arts – Communication Studies</li>
            <li className="my-1">Associate of Arts in Media Communication</li>
            <li className="my-1">Advanced Diploma in Advertising and Marketing Communications Management</li>
            <li className="my-1">Diploma in Graphic Communications</li>
            <li className="my-1">Graduate Diploma in Public Relations and Communications Management</li>
            <li className="my-1">Bachelor of Arts in Rhetoric, Writing, and Communications</li>
            <li className="my-1">Graduate certificate in Technical Communication</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 19 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
      Law
      </button>
    </h2>
    <div id="collapseNineteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <div className="row mx-0">
            <div className="col-md-6">
                <p>UG:- Generally Students from commerce and Arts field enter this field. However, science students can also apply to these courses.</p>
                <ul>
                    <li className="my-1">Bachelor of Arts in Law & Business – Dual Degree (LL.B. & B.B.A.)</li>
                    <li className="my-1">Diploma in Community and Justice Services</li>
                    <li className="my-1">Diploma in Office Administration – Legal</li>
                    <li className="my-1">Diploma in Criminology</li>
                    <li className="my-1">Bachelor of Arts (Honours) in Criminology and Justice </li>
                    <li className="my-1">Bachelor of Science in Forensic Science </li>
                    <li className="my-1">Diploma in Legal Studies .</li>
                    <li className="my-1">Diploma in Paralegal</li>
                    <li className="my-1">Diploma in Law Clerk</li>
                    <li className="my-1">Diploma in Legal Assistant</li>
                </ul>
            </div>
            <div className="col-md-6">
                <p>PG:- A first- or second-class honours degree qualification in Law and an understanding of the basic principles of public and private law.</p>
                <ul>
                    <li className="my-1">Post Degree Diploma in Business Law</li>
                    <li className="my-1">Graduate Certificate in Advanced Law Enforcement and Investigations</li>
                    <li className="my-1">Graduate Certificate in Paralegal</li>
                    <li className="my-1">Graduate Certificate in Victimology</li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 20 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
      Life Sciences
      </button>
    </h2>
    <div id="collapseTwenty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Science subjects, such as chemistry, biology and physics, are essential, and maths is often strongly recommended. Most Life Science degrees will require an 60% or more in one of geography, biology, maths or physics. PG :- Biological and Medical Science background will be required.</p>
        <h6>List of Courses :-</h6>
        <ol>
            <li className="my-1">Masters of Applied Human Nutrition</li>
            <li className="my-1">Bachelor of Science in Biochemistry</li>
            <li className="my-1">Bachelor of Science in Biology</li>
            <li className="my-1">Bachelor of Science – Applied Human Nutrition (Nutrition) Co-op</li>
            <li className="my-1">Bachelor of Science in Biomedical Science – Honours</li>
            <li className="my-1">Bachelor of Science in Agricultural Biotechnology</li>
            <li className="my-1">Bachelor of Science in Neuroscience</li>
            <li className="my-1">Bachelor of Anatomy and Cell Biology ( Major in Medical Cell Biology)</li>
            <li className="my-1">Bachelor of Horticulture Science in Plant Health</li>
            <li className="my-1">Diploma in Bio-Science Technology</li>
            <li className="my-1">Bachelor of Science in Marine Biology</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}

  {/* Tab 21 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
      Physics
      </button>
    </h2>
    <div id="collapseTwentyOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>To get on to a Physics related degree, students will usually require at least 80% in subjects including Physics and Maths. For Chemistry degrees, students will need Chemistry 80%, with some universities preferring a second science subject.</p>
        <h6>List of Courses :-</h6>
        <ol>
            <li className="my-1">Bachelor of Science in Applied Physics</li>
            <li className="my-1">Bachelor of Science in Astronomy</li>
            <li className="my-1">Bachelor of science in Chemistry</li>
            <li className="my-1">Bachelor of science in Cognitive Science</li>
            <li className="my-1">Bachelor of Science in Physics</li>
            <li className="my-1">Diploma in Geological Resources</li>
            <li className="my-1">Bachelor of Science in Applied Water Science (Co-op)</li>
            <li className="my-1">Bachelor of Science Data Science (Co-op)</li>
            <li className="my-1">Bachelor of Science in Environmental Geoscience</li>
            <li className="my-1">Bachelor of Science in Anthropology</li>
            <li className="my-1">Bachelor of Science in Applications of Mathematics</li>
            <li className="my-1">Bachelor of Horticulture Science in Urban Ecosystem</li>
            <li className="my-1">Bachelor of Science in Geography</li>
            <li className="my-1">Bachelor of Science in Actuarial Science</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 22 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
      Pharmacy
      </button>
    </h2>
    <div id="collapseTwentyTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Generally , subjects such as Maths , English and Chemistry are required. Many universities and colleges require more than 80% in science subjects.</p>
        <p>PG:- Relevant Degrees in Biology and Chemistry are generally required for Pharmacy degrees.</p>
        <h6>List of Courses:-</h6>
        <ol>
            <li>Diploma in Pharmacy Technician</li>
            <li>Certificate in Community Pharmacy Assistant</li>
            <li>Bachelor in Pharmacy</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 23 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
      Social and Behavior Science
      </button>
    </h2>
    <div id="collapseTwentyThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>UG:- Students from Arts background are usually preferred for such courses but students from other streams can also apply.</p>
        <p>PG:- Applicant should have a degree in relevant discipline.</p>
        <h6>List of Courses:-</h6>
        <ol>
            <li className="my-1">Bachelor of Arts in Sociology</li>
            <li className="my-1">Bachelor of Arts in Psychology</li>
            <li className="my-1">Bachelor of Arts in International Relations</li>
            <li className="my-1">Bachelor of Arts – Sociology & Anthropology</li>
            <li className="my-1">Post Baccalaureate Certificate in Applied Behaviour Analysis</li>
            <li className="my-1">Advanced Diploma in Behavioural Science</li>
            <li className="my-1">Graduate Certificate in Autism and Behavioural Science</li>
            <li className="my-1">Diploma in Humanities and Social Sciences</li>
            <li className="my-1">Diploma in Social Sciences – Civilization & Citizenship – DEC</li>
            <li className="my-1">Diploma in Settlement Studies</li>
            <li className="my-1">Bachelors of Arts in Native Studies</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 24 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
      Sports
      </button>
    </h2>
    <div id="collapseTwentyFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Entry requirements for such courses isn’t any specific and institutions accept students from all background provided the intent to study is well explained in SOP. Students with good marks in physical Education subject  (Grade 12) are recommended to apply for these programs </p>
        <h6>List of Courses :- </h6>
        <ul>
            <li className="my-1">Diploma in Sport Science</li>
            <li className="my-1">Diploma in Sport Administration</li>
            <li className="my-1">Diploma in Kinesiology & Sports Studies </li>
            <li className="my-1">Diploma in Recreation, Sport and Leisure</li>
            <li className="my-1">Diploma in Strength and Sport Conditioning </li>
            <li className="my-1">Diploma in Esports Entrepreneurship & Administration </li>
            <li className="my-1">Advanced Diploma in Sport Management </li>
            <li className="my-1">Bachelor of Science in Kinesiology – Exercise and Sport Studies</li>
        </ul>
        <p>Students from B.p</p>
        <ul>
            <li className="my-1">Graduate certificate in Event Marketing – Sports, Entertainment, Arts</li>
            <li className="my-1">Graduate Certificate in Sport Business Management</li>
            <li className="my-1">Graduate Certificate in Sports Journalism </li>
            <li className="my-1">Graduate Certificate in Sport and Event Marketing</li>
            <li className="my-1">Graduate Certificate in Esports Management</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 25 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
      Designated Learning Institutions
      </button>
    </h2>
    <div id="collapseTwentyFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Student need to have a letter of acceptance from a designated learning institution. Each province and territory in Canada is responsible for designating educational  institutions at the post‐secondary level that may enroll international students on  or after 1st June 2014.</p>
        <p>This list of designated learning institutions can be found on the below mentioned</p>
        <p><a href="http://www.cic.gc.ca/english/study/study‐institutions‐list.asp">Click Here</a></p>
        <p>If a study permit application is made on or after 1st June 2014 and the letter of  acceptance is from an institution that is not designated for international students,  the visa application will be refused.</p>
      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 26 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
      Total Cost for Diploma for 1 year
      </button>
    </h2>
    <div id="collapseTwentySix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col">Sr. No.</th>
      <th scope="col">Particulars</th>
      <th scope="col">Amount in CAD $</th>
      <th scope="col">Amount in Rs.</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>IELTS Coaching Fee (Refundable)*</td>
      <td></td>
      <td>8,500</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>IELTS Exam Fee</td>
      <td></td>
      <td>17,000</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Tuition Fees for one year complete course  (Actual tuition fees may vary from  University to University / College to College)</td>
      <td>15,000</td>
      <td>9,16,523</td>
    </tr>

    <tr>
      <th scope="row">4</th>
      <td>Living and Accommodation</td>
      <td>20,635</td>
      <td>12,60,829</td>
    </tr>

    <tr>
      <th scope="row">5</th>
      <td>Airfare from India to Canada</td>
      <td></td>
      <td>65,000*</td>
    </tr>

    <tr>
      <th scope="row">6</th>
      <td>Visa Fees</td>
      <td>235</td>
      <td>14,359</td>
    </tr>

    <tr>
      <th scope="row">7</th>
      <td>Total Expenses</td>
      <td>47,370</td>
      <td>2,152,083</td>
    </tr>

    <tr>
      <th scope="row">8</th>
      <td>(In Part Time Jobs you get CAD $11 per hr. & you can work  24 hrs. per week during study time & 40 hrs. per week  during holidays i.e. Summer, Christmas, Easter)</td>
      <td>11,500</td>
      <td>7,02,667</td>
    </tr>

    <tr>
      <th scope="row">9</th>
      <td>Total Expenses for Study in Canada</td>
      <td>58,870</td>
      <td>2,750,083</td>
    </tr>

    <tr>
      <th scope="row">10</th>
      <td>Total Round Off Expenses</td>
      <td></td>
      <td>2,750,000</td>
    </tr>

  </tbody>
</table>

      </div>
    </div>
  </div>

  {/* Repeat the above structure for the remaining tabs */}
  
  {/* Tab 27 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
      Living Expenses
      </button>
    </h2>
    <div id="collapseTwentySeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li className="my-1">In addition to tuition, students must also account for books, housing, food, and other day-to-day living expenses. Costs are generally more expensive in bigger cities and will also depend on a student’s lifestyle, choice of accommodations, and spending habits.</li>
            <li className="my-1">Off-campus costs assume the student is living independently. Students can lower costs by sharing off-campus housing with other students.</li>
            <li className="my-1">Books and supplies costs vary by program.</li>
            <li className="my-1">Utilities and Internet are included and available at on-campus residences.</li>
            <li className="my-1">Transportation is not needed on a daily basis if a student is living on-campus as the residences are located within walking distance of the campus and its classrooms.</li>
            <li className="my-1">When it comes to food, some institutions require students to purchase a meal plan. Students can use the money added to their meal plan to purchase meals from on-campus cafeterias and restaurants.</li>
        </ul>
        <img src="/img/resource-canada7.png" className='img-fluid' alt="" />
      </div>
    </div>
  </div>
</div>

    </>
  )
}

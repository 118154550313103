import React from 'react'
import ausbg from '../../assets/imgnew/gmatbg.jpg'
import ausflag from '../../assets/img/gmatlogo2.png'

export default function Gmatpage() {
  return (
    <>
              {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Boost Your GMAT Score With GlobEDwise</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Experienced and Certified GMAT trainers</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Authentic and Researched course material of premium quality</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Online/Offline question bank library for GMAT</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Customized Training Session for better concept clarity</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Regular weekdays and weekend batches</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Training sessions are in accordance with the recent changes proposed in GMAT</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4 bg-light p-2' style={{width:'120px'}} alt='flag'/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Boost Your GMAT Score With GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>GMAT</span></h4>
    </div>

    <div className="container">
        <h5>The GMAT Advantage</h5>
        <p>The GMAT exam is a trusted part of the admissions process for business and management programs worldwide, including full-time and part-time MBA and Masters degree programs.</p>

        <h5>Who Takes the GMAT?</h5>
        <p>More than 100,000 business school candidates every year take the GMAT exam exclusively to apply to a graduate management program. In fact, 7 in 10 applicants who apply with an admissions score to the top 100 ranked MBA programs use the GMAT exam.</p>

        <h5>What Schools Accept the GMAT?</h5>
        <p>More than 7,700 programs at 2,400 universities and organizations in 110 countries use the GMAT exam as part of the selection criteria for their programs. Whether you want to study local, abroad, or online, there is a school for you!</p>

        <h5>Should You Take the GMAT or the GRE?</h5>
        <ul>
            <li>The GMAT is designed specifically for business school admissions.</li>
            <li>Taking the GMAT exam means you are serious about b-school. 80% of applicants prefer use a GMAT score when applying to business school.</li>
            <li>The GMAT increases your earning potential and opens doors.</li>
            <li>The GMAT exam also prepares you to realize your career potential. 92% of corporate recruiters say they expect to hire newly graduated MBAs in 2022.</li>
        </ul>

        <p>Register We are here to help make sure you are ready!</p>

        <h5>Get started with 3 easy steps</h5>
        <ul>
            <li>Choose your exam delivery experience. Decide which delivery method works best for you – online or at a test center, the choice is yours!</li>
            <li>Create an mba.com account. Start by creating an mba.com account and providing some basic information</li>
            <li>Register for your exam. Click the register button, complete your GMAT profile, select your date and time, and complete payment.</li>
        </ul>

        <p>Tip:- Plan ahead for application deadlines! It typically takes 7 business days for your Official Score Report to become available, but it may take up to 20 business days in some cases.</p>

        <p>On the day of your exam, you must present acceptable and valid identification. In addition to the information below, some ID requirements also vary by location. What are the acceptable forms of ID? International travel passport: A passport is always required when taking the exam at a location outside of your country of citizenship. Expired passports are not acceptable and renewal papers do not make an expired passport valid</p>

        <p>In some locations, additional forms of ID (other than a passport) may be acceptable. Be sure to check the requirements for your location here.</p>

        <ul>
            <li>Your ID must be current, legible, and include:</li>
            <li>Your name using the Roman alphabet, shown exactly as you provided when you registered, including the order and placement of your name(s)</li>
            <li>Date of birth that matches exactly the date provided when you registered</li>
            <li>A recent, recognizable photograph</li>
            <li>Your signature</li>
        </ul>

        <p>Test Center Appointment: If you are unable to provide one form of ID that includes ALL of the above elements at your test center, you will be required to present a second ID that is acceptable for your location that includes the missing elements.</p>

        <ul className="list-group mb-3">
            <li className="list-group-item">Expired identification</li>
            <li className="list-group-item">Passport cards</li>
            <li className="list-group-item">Social security cards</li>
            <li className="list-group-item">Draft classification cards</li>
            <li className="list-group-item">Credit cards</li>
            <li className="list-group-item">Employee or student IDs</li>
            <li className="list-group-item">Letters from your university or college</li>
            <li className="list-group-item">IDs with physical descriptions in place of photos</li>
            <li className="list-group-item">Photocopies of any form of ID</li>
            <li className="list-group-item">Selfies, portraits, or profile photos</li>
            <li className="list-group-item">Any other kind of ID that does not meet the requirements described above</li>
        </ul>    

        <h5>What do I need to know for exam day?</h5>
        <p>Test Center Appointment: On exam day, if you do not have an appropriate ID with you at the test center, you will forfeit your exam fee, and you will need to pay the full fee again to reschedule. There are no exceptions. Online Appointment: On exam day, you MUST have previously uploaded (at least 48 hours prior to your scheduled exam) an appropriate ID during registration AND have the exact same ID with you for the online proctor to verify. If you do not, you will forfeit your exam fee, and you will need to pay the full fee again to reschedule. There are no exceptions</p>

        <h5>Exam Scores</h5>
        <p>GMAT exam scores inform your next steps towards business school and serve as a crucial factor during the admissions process</p>
        <p>Your Official Score Report includes several ways to interpret your exam results. Each of your five GMAT exam scores (Quantitative Reasoning, Verbal Reasoning, Integrated Reasoning, Analytical Writing Assessment, and Total) is reported on a fixed scale and includes a percentile ranking. This information (along with your background information such as your GMAT ID, exam date, appointment number, etc.) appears on the Official GMAT Score Report that you and your designated score recipients (graduate business programs) receive. Your score is valid for five years, giving you the flexibility to send your score to schools when you are ready.</p>

        <h5>The Details of Your Score Report</h5>
        <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Section</th>
                    <th>Scoring</th>
                    <th>How the Section is Scored</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Quantitative Reasoning</td>
                    <td>6-51</td>
                    <td>Scores are reported in intervals of 1 and the standard error of measurement is 3 points.</td>
                </tr>
                <tr>
                    <td>Verbal Reasoning</td>
                    <td>6-51</td>
                    <td>Scores are reported in intervals of 1 and the standard error of measurement is 3 points.</td>
                </tr>
                <tr>
                    <td>Integrated Reasoning</td>
                    <td>1-8</td>
                    <td>Scores are reported in intervals of 1.</td>
                </tr>
                <tr>
                    <td>Analytical Writing Assessment</td>
                    <td>0.0-6.0</td>
                    <td>Scores are reported in intervals of 0.5.</td>
                </tr>
                <tr>
                    <td>Total Score</td>
                    <td>200-800</td>
                    <td>Scores are reported in intervals of 10. The standard error of measurement is 30-40 points.</td>
                </tr>
            </tbody>
        </table>

        <h5>Score Penalty for Unanswered Questions</h5>
        <p>There is a penalty for not completing each section of the exam. If you do not finish in the allotted time, your score will be calculated based upon the number of questions answered. Your score will decrease significantly with each unanswered question. It is better to make your best guess and complete all questions rather than omit questions!</p>

    </div>

    </>
  )
}

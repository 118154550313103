import React from 'react'
import ausbg from '../../assets/imgnew/finbeebg2.jpg'
import ausflag from '../../assets/img/toefllogo.png'
import logo1 from '../../assets/illustrations/studyabroad2.png'
import logo2 from '../../assets/illustrations/studyabroad5.png'
import logo3 from '../../assets/illustrations/studyabroad7.png'

export default function Toeflpage() {
  return (
    <>
     {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Improve Your TOEFL Score With GlobEDwise</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> We offer simulated Mock Tests that accurately reflect the test conditions.</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Certified TOEFL trainers with great experience and classroom Training exposure</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Researched course material</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Unlimited Mock Tests and technology based Evaluation</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Customized Online/offline Training sessions</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Tailor made sessions and one to one feedback & discussion</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4 bg-light p-2' style={{width:'120px'}} alt='flag'/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Improve Your TOEFL Score With GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}


<div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>TOEFL</span></h4>
    </div>

    <div className="container">
        <h5>About TOEFL</h5>
        <p>The TOEFL iBT test helps you stand out confidently in English. It’s the only test that measures all four academic English skills — reading, listening, speaking and writing — the way they are actually used in a classroom, so you can be confident you’ll stand out to universities where it counts.</p>

        <h5>Why Choose the TOEFL iBT Test?</h5>
        <div className="img-text d-flex flex-row align-items-center my-3">
        <img src={logo1} className='img-fluid mx-2' alt="" style={{width:'150px'}}/>
        <h6 className='text-dark'>Accepted worldwide by 11,500+ universities</h6>
        </div>
        <div className="img-text d-flex flex-row align-items-center my-3">
        <img src={logo2} className='img-fluid mx-2' alt="" style={{width:'150px'}}/>
        <h6 className='text-dark'>Test at home or at a test center in your area</h6>
        </div>
        <div className="img-text d-flex flex-row align-items-center my-3">
        <img src={logo3} className='img-fluid mx-2' alt="" style={{width:'150px'}}/>
        <h6 className='text-dark'>Get your scores fast (as soon as 4 days)</h6>
        </div>

        <h5>Where to Take Your TOEFL iBT Test</h5>
        <p>You can take the TOEFL iBT test at a test center or at home. Learn more about the testing options or view the full list of countries where the TOEFL iBT test is available.</p>

        <h5>At Home Testing Requirements</h5>
        <p>To take the TOEFL iBT Home Edition or Paper Edition, you’ll need to meet the Equipment and Environment Requirements for athome testing.</p>

        <h5>On Test Day</h5>
        <h6>ID requirements in the country/location where you plan to test</h6>
        <p>Select the country/location where you plan to take the test to see information about specific ID requirements.</p>

        <h5>India acceptable primary ID documents</h5>
        <h6>Testing within your country of citizenship</h6>
        <p>The following ID documents are acceptable for admission to a test session within your country of citizenship</p>
        <ul>
            <li>Passport with name, photograph and signature</li>
            <li>ETS is temporarily accepting the Aadhaar Card as a primary ID for test takers in India until further notice. The Aadhaar Card must be an original document. Photocopied, printed documents are not acceptable.</li>
        </ul>
        <h6>Testing outside your country of citizenship</h6>
        <p>You must present a valid passport with your name, photograph and signature as your primary ID. There are no exceptions to this policy.</p>

        <h5>Additional requirements and policies</h5>
        <p>The following documents are not acceptable as primary or supplemental ID under any circumstances:</p>
        <ul className="list-group mb-2">
            <li className="list-group-item">International driver’s license</li>
            <li className="list-group-item">Mobile driver’s license</li>
            <li className="list-group-item">Draft classification card</li>
            <li className="list-group-item">International student ID</li>
            <li className="list-group-item">Credit/debit card of any kind</li>
            <li className="list-group-item">Notary-prepared letter or document</li>
            <li className="list-group-item">Birth certificate</li>
            <li className="list-group-item">Social Security card</li>
            <li className="list-group-item">Employee ID card</li>
            <li className="list-group-item">Any temporary ID card</li>
        </ul>
        <p>If your ID document is not written in English-language letters and the test center administrator cannot read it, you may not be able to take the test, and your test fee will not be refunded.</p>

        <h6>You might have to provide a supplemental ID document if:</h6>
        <ul className="list-group mb-2">
            <li className="list-group-item">the proctor questions your primary ID document</li>
            <li className="list-group-item">your primary ID document is otherwise acceptable but is missing your full name, photograph or signature</li>
        </ul>
        <p>You can’t use supplemental ID documents to resolve last-name discrepancies; you’ll need to contact TOEFL Services before test day to resolve.</p>
        <p>If you can’t provide acceptable supplemental ID (see below) that includes your signature, you can present two government-issued IDs with photographs, as long as they’re in the same name you used when you registered</p>

        <h5>Getting Your TOEFL iBT Scores</h5>
        <p>Your scores will be available in your ETS account. How soon they’ll be available depends on how you took the test:</p>
        <ul className="list-group mb-2">
            <li className="list-group-item">Taken at a test center: 4–8 days after your test date</li>
            <li className="list-group-item">TOEFL iBT Home Edition: 4–8 days after your test date</li>
            <li className="list-group-item">TOEFL iBT Paper Edition: 11–13 business days after your test date</li>
        </ul>

        <h5>Through your ETS account</h5>
        <p>You’ll get an email when your scores are available, and you can access your account online. Your scores are valid for 2 years, so you may want to download and print a PDF copy of your score report. PDF score reports are ready for download 2 days after you receive your scores electronically through your ETS account, or you can view this list of score reporting dates (PDF) for an estimated availability date, for tests taken at a test center.</p>

        <h5>Sending Your TOEFL iBT Scores</h5>
        <h6>Choosing score recipients before the test</h6>
        <p>Your test fee includes up to four free official TOEFL score reports to be sent to the institutions, agencies or other recipients that you select before you take the test. You can add or delete recipients through your ETS account until 10 p.m. (local test center time) on the day before your test. If you choose any institution or agency to receive your scores, you won’t be able to request a score review of your Writing and/or Speaking sections.</p>

        <h6>When institutions will receive your scores</h6>
        <p>Score delivery time varies based on how you took the test and how your designated score recipients receive scores (all times approximate)</p>

        <ul>
            <li>ETS Data Manager: 4–8 business days (11–13 business days for Paper Edition)</li>
            <li>Server feeds: 8–10 business days (11–16 business days for Paper Edition)</li>
            <li>Postal mail: 10–12 business days plus mailing time. Allow an additional 7–10 days for mail delivery in the United States, and 4–6 weeks for mail delivery to other locations.</li>
        </ul>

        <h6>Additional score reports</h6>
        <p>Your scores are valid for 2 years after your test date. During that time, you can order additional score reports for a fee of US$20 per institution/agency. The easiest way to order is through your ETS account. To order by fax or mail, complete the TOEFL iBT Additional Score Report Request Form (PDF) and send to:</p>

        <ul className="list-group mb-2">
            <li className="list-group-item">Fax: 1-610-290-8972</li>
            <li className="list-group-item">Mail: ETS-TOEFL iBT; P.O. Box 6153; Princeton, NJ 08541-6153; USA</li>
        </ul>

        <h5>Understanding Your TOEFL iBT Scores</h5>
        <h6>What do your scores mean?</h6>
        <p>You’ll receive four scaled section scores and a total score.</p>
        <p>Each skill has four or five proficiency levels, so where your score falls within that range tells you your proficiency for that skill.</p>

        <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Skill</th>
                    <th>Level</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Reading</td>
                    <td>
                        Advanced (24–30)<br />
                        High-Intermediate (18–23) <br />
                        Low-Intermediate (4–17) <br />
                        Below Low-Intermediate (0–3)
                    </td>
                </tr>
                <tr>
                    <td>Listening</td>
                    <td>
                        Advanced (22–30)<br />
                        High-Intermediate (17–21) <br />
                        Low-Intermediate (9–16) <br />
                        Below Low-Intermediate (0–8)
                    </td>
                </tr>
                <tr>
                    <td>Speaking</td>
                    <td>
                        Advanced (25–30)<br />
                        High-Intermediate (20–24) <br />
                        Low-Intermediate (16–19) <br />
                        Basic (10-15)
                        Below Basic (0–9)
                    </td>
                </tr>
                <tr>
                    <td>Writing</td>
                    <td>
                        Advanced (24–30)<br />
                        High-Intermediate (17–23) <br />
                        Low-Intermediate (13–16) <br />
                        Basic (7-12)
                        Below Basic (0–6)
                    </td>
                </tr>

                </tbody>
                </table>

    </div>


    </>
  )
}

import "./App.css";
import "./assets/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainHome from "./components/MainHome";
import Footer from "./components/includes/Footer";
import NewHeadercopy from "./components/includes/NewHeadercopy";
import React from "react";
import Mobileheadernew from "./components/includes/Mobileheadernew";
import Redirect from "./components/Redirect";
// import Thankyoupage from "./components/Thankyoupage";
import ScrollToTop from "./components/ScrollToTop";
// import PrivacyPolicy from "./components/PrivacyPolicy";

import Canadaprogram from "./components/Canadaprogram";
import Checkprogram from "./components/Chekprogram";
import Admissioneval from "./components/Admissioneval";
import Tutorials from "./components/Tutorials";
import Canadapage from "./country/Canadapage";
import Ukpage from "./uk/Ukpage";
import Uspage from "./usa/Uspage";
import Auspage from "./australia/Auspage";
import Gerpage from "./germany/Gerpage";
import Irepage from "./ireland/Irepage";
import Packages from "./components/Packages";
import Singapage from "./singapore/Singapage";
import Ptepage from "./(admission test)/pte/Ptepage";
import Duolingopage from "./(admission test)/duolingo/Duolingopage";
import Grepage from "./(admission test)/gre/Grepage";
import Gmatpage from "./(admission test)/gmat/Gmatpage";
import Toeflpage from "./(admission test)/toefl/Toeflpage";
import Satpage from "./(admission test)/sat/Satpage";
import Lorpage from "./(documentation)/lor/Lorpage";
import Soppage from "./(documentation)/sop/Soppage";
import Resumepage from "./(documentation)/resume/Resumepage";
import Finland from "./finland/Finland";
import ContactInformation from "./components/ContactInformation";
import Latvia from "./latvia/Latvia";
import Partner from "./components/Partners";
import Denmark from "./denmark/Denmark";
import Sweden from "./sweden/Sweden";
import France from "./france/France";
import SouthKorea from "./south-korea/SouthKorea";
function App() {
  return (
    <Router>
      <NewHeadercopy />
      <Mobileheadernew />
      <ScrollToTop>
        <Routes>
          {/* Main pages routes */}
          <Route path="*" element={<Redirect title="404" />}></Route>
          <Route
            path="/"
            element={
              <MainHome title="GlobEDwise - Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route
            path="/partner-institutions"
            element={
              <Partner title="Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route
            path="/canada-program-availability"
            element={
              <Canadaprogram title="Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route
            path="/check-program-availability"
            element={
              <Checkprogram title="Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route
            path="/contact-information"
            element={
              <ContactInformation title="Contact Information" />
            }
          ></Route>
          <Route
            path="/admission-evaluator-tool"
            element={
              <Admissioneval title="Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route
            path="/tutorials"
            element={
              <Tutorials title="Study Abroad - Overseas Education and Immigration" />
            }
          ></Route>
          <Route path="/globedwise-packages" element={<Packages />}></Route>
          {/* Main pages routes */}
          <Route path="/canada" element={<Canadapage />}></Route>
          <Route path="/uk" element={<Ukpage />}></Route>
          <Route path="/usa" element={<Uspage />}></Route>
          <Route path="/australia" element={<Auspage />}></Route>
          <Route path="/germany" element={<Gerpage />}></Route>
          <Route path="/ireland" element={<Irepage />}></Route>
          <Route path="/singapore" element={<Singapage />}></Route>
          <Route path="/finland" element={<Finland />}></Route>
          <Route path="/latvia" element={<Latvia />}></Route>
          <Route path="/denmark" element={<Denmark />}></Route>
          <Route path="/sweden" element={<Sweden />}></Route>
          <Route path="/france" element={<France />}></Route>
          <Route path="/south-korea" element={<SouthKorea/>}></Route>


          <Route path="/pte" element={<Ptepage />}></Route>
          <Route path="/duolingo" element={<Duolingopage />}></Route>
          <Route path="/gre" element={<Grepage />}></Route>
          <Route path="/gmat" element={<Gmatpage />}></Route>
          <Route path="/toefl" element={<Toeflpage />}></Route>
          <Route path="/sat" element={<Satpage />}></Route>
          <Route path="/lor" element={<Lorpage />}></Route>
          <Route path="/sop" element={<Soppage />}></Route>
          <Route path="/resume" element={<Resumepage />}></Route>
        </Routes>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}

export default App;

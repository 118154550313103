import React from 'react'
import ausbg from '../../assets/imgnew/elitebg.jpg'
import ausflag from '../../assets/imgnew/duolingo.jpg'

export default function Duolingopage() {
  return (
    <>
      {/* hero section start */}
      <div id='#customcontainer' className="customcontainer mt-4 pt-3">
        <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
          backgroundImage: `url(${ausbg})`,
          height: '500px',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backgroundBlendMode: 'overlay'
        }}>
          <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
            <div className="col-md-8 ieltslandingcontent">
              <h1 className="bannerHeading text-light">Upgrade Your Duolingo Skills With GlobEDwise</h1>
              <ul className="bannerPoints text-dark">
                <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Developed for today’s worldwide students and institutions</li>
                <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Duolingo English Test is a contemporary language competence evaluation tool</li>
                <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> It provides a video interview, writing sample, and English proficiency score</li>
                <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> The exam can be taken online at any time and from any location</li>
                <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> This test takes one hour to complete, and the results are available in two days</li>
              </ul>
              <img src={ausflag} className='mt-2 mx-4' style={{ width: '120px' }} alt='flag'/>
            </div>

            <div className="ieltslandingcontentmobile" style={{ display: 'none' }}>
              <h1 className="bannerHeading text-light">Upgrade Your Duolingo Skills With GlobEDwise</h1>
            </div>



          </div>

        </div>

      </div>
      {/* hero section ended */}

      <div className="container py-3">
        <h2 className='text-danger'>Duolingo Test</h2>
        <p>Developed for today’s worldwide students and institutions, the Duolingo English Test is a contemporary language competence evaluation tool. It provides a video interview, writing sample, and English proficiency score in a convenient, effective, and secure testing environment.</p>
        <p>The exam can be taken online at any time and from any location. This test takes one hour to complete, and the results are available in two days. It is accepted by over 4000 institutions.</p>

        <h5>Test Format</h5>
        <p>Reading, Speaking, Listening, and Writing are the four sections of the Duolingo English Test, which may be finished in around 45 minutes. The Duolingo exam is offered twice a month to students. In order to evaluate a student’s performance, it employs both human raters and automatic scoring techniques.</p>

        <h5>What is the cost?</h5>
        <p>The registration fee is $49 (or INR 3700/- ). More details on fee and dates are found here.’</p>

        <h5>What is the score?</h5>
        <p>Each test taker’s proficiency is reported as a holistic score on a scale from 10 – 160.</p>

        <h5>How long does it take to get the results?</h5>
        <p>The results are posted on the DET website 2 days after the test. The scores are even personallymailed to the candidates.</p>

        <h5>How long is the score valid?</h5>
        <p>Results for the Duolingo English Test are valid for two years.</p>

        <h5>What is needed to take the test?</h5>
        <p>To the testing location, students must carry two items –</p>
        <ul>
          <li>Internet connection and computer with a video camera</li>
          <li>Microphone</li>
          <li>Headphones</li>
          <li>Acceptable, legitimate identity (ID) document(s)</li>
        </ul>

        <h5>Which academic institutions or schools recognise the Duolingo English Test?</h5>
        <p>Numerous universities and secondary schools that take the Duolingo English Test as part of their admissions process are among the organizations that Duolingo partners with throughout the world. Please visit this page – http://englishtest.duolingo.com/partners to get a complete list of institutions that accept the Duolingo English Test and how they do so.</p>

        <h5>When and where will the Duolingo English Test be offered?</h5>
        <p>You can select your own testing location and time, which is one benefit of the Duolingo English Test.To help you decide where and when to take the test, here are some general tips.</p>

        <h5>Can I get a Visa with Duolingo test?</h5>
        <p>There aren’t any online language exams that are currently accepted for immigration. Duolingo cannot be used. To fulfill academic requirements and demonstrate English proficiency, this test is used. For UG/PG admissions, many colleges and universities have varied prerequisite scores for Duolingo. https://englishtest.duolingo.com/institutions</p>

      </div>
    </>
  )
}

import React from 'react'
import ausbg from '../../assets/imgnew/ptebg.jpg'
import ausflag from '../../assets/img/ptelogo.png'

export default function Ptepage() {
  return (
    <>
              {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Upgrade Your PTE Skills With GlobEDwise</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Around the clock Sessions</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> 90% students achieved 85+ score in PTE</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Average student class rating is 4.5/5</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> PTE Mock test is evaluated electronically, similar to the PTE exam</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Regular weekdays and weekend batches</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Tailor made sessions and one to one feedback & discussion</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4' style={{width:'120px'}} alt='flag'/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Upgrade Your PTE Skills With GlobEDwise</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container py-3">

    <h2 className="text-danger">PTE Academic: the computer-based English test</h2>
    <h5>PTE Academic measures your English speaking, listening, reading, and writing skills in a single, short test.</h5>

    <h5>1-Advantages of taking IELTS</h5>
    <p>A two-hour test via computer No more waiting around for your speaking exam – PTE Academic measures English speaking, listening, reading, and writing in just two hours</p>
    
    <h5>2- Frequent test dates, last-minute availability</h5>
    <p>We run regular test dates with last-minute availability. Check now to findout how soon you can take PTE Academic</p>

    <h5>3- Friendly test center experience</h5>
    <p>Take your test in a small, friendly test center environment – with no more than15 seats per test room.</p>

    <h5>TEST FORMAT</h5>
    <p>There are 20 different question types in the test, ranging from multiple choices through to essay writing. Make sure you review the different question formats ahead of your test, as each part has different types of questions.</p>

    <h5>Part 1: Speaking & Writing – 54-67 MINUTES</h5>
    <p>As the name implies, this section tests the candidates based on their 2 major skills of communication, which are spoken and written skills. This section comprises six small sections that test you on your promptness over speaking and writing the written test that you would get to read for the first time. The complete section is given a time duration of 54 – 67 minutes and the time allotted to each segment is as follows:</p>

    <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Question Topics</th>
                    <th>PTE Questions per task (w.e.f November 16, 2021)</th>
                    <th>Duration per task</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Personal introduction</td>
                    <td>1</td>
                    <td>55 seconds: 25 seconds for prompt, 30 seconds to record</td>
                </tr>
                <tr>
                    <td>Read Aloud</td>
                    <td>6 – 7</td>
                    <td>30-40 seconds to prepare for reading out the text of 60 words</td>
                </tr>
                <tr>
                    <td>Repeat sentence</td>
                    <td>10 – 12</td>
                    <td>15 seconds: 3-9 seconds for prompt, 15 seconds to record</td>
                </tr>
                <tr>
                    <td>Describe image</td>
                    <td>3 – 4</td>
                    <td>25 seconds are granted to study the image as well as prepare your response on the same</td>
                </tr>
                <tr>
                    <td>Retell lecture</td>
                    <td>1 – 2</td>
                    <td>90 seconds for prompt length, 10 seconds to prepare and 40 seconds to answer</td>
                </tr>
                <tr>
                    <td>Answer short question</td>
                    <td>5 – 6</td>
                    <td>20 seconds: 3-9 seconds for prompt, 10 seconds to answer</td>
                </tr>
                <tr>
                    <td>Summarize written text</td>
                    <td>1 – 2</td>
                    <td>10 minutes to answer to text prompt of 300 words</td>
                </tr>
                <tr>
                    <td>Write Essay</td>
                    <td>1 – 2</td>
                    <td>20 minutes to answer to text prompt of 2-3 sentences</td>
                </tr>
                <tr>
                    <td>Total questions</td>
                    <td>28 – 36</td>
                    <td>Time Allocation: 54 – 67 minutes</td>
                </tr>
            </tbody>
        </table>

        <h2 className="text-danger">PTE Exam Pattern: Reading Section — 29 – 30 mins</h2>
        <p>PTE Reading Format: This section tests the candidates on the basis of their ability to understand the written instructions in the language. The section further divided into 5 segments is 29 to 30 minutes long. Check out the segment-wise pattern for the time distribution of this section:</p>

        <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Question Topics</th>
                    <th>PTE Questions per task (w.e.f November 16, 2021)</th>
                    <th>Duration per task</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Fill in the blanks – reading & writing</td>
                    <td>5 – 6</td>
                    <td>Read 300 words of text</td>
                </tr>
                <tr>
                    <td>Multiple choice, multiple answers</td>
                    <td>1 – 2</td>
                    <td>Read 300 words of text</td>
                </tr>
                <tr>
                    <td>Re-order paragraph</td>
                    <td>2 – 3</td>
                    <td>Read 150 words of text</td>
                </tr>
                <tr>
                    <td>Fill in the blanks – reading</td>
                    <td>4 – 5</td>
                    <td>Read 80 words of text</td>
                </tr>
                <tr>
                    <td>Multiple choice, single answer</td>
                    <td>1 – 2</td>
                    <td>Read 300 words of text</td>
                </tr>
                <tr>
                    <td>Total questions</td>
                    <td>13 – 18</td>
                    <td>Time Allocation: 29 – 30 minutes</td>
                </tr>
            </tbody>
        </table>

        <h2 className="text-danger">PTE Exam Pattern: Listening — 30 – 43 minutes</h2>
        <p>PTE Listening Format: The listening section of the PTE Academic is stretched over 30 to 43 minutes of duration. This one is designed to determine the ability to understand spoken English by the candidate. Here, the student needs to ascertain that they carefully hear the audio file played and retain what they heard. This section is divided into 8 segments and the time given to each segment is as follows</p>

        <table className="table table-bordered">
            <thead className="table-dark">
                <tr>
                    <th>Question Topics</th>
                    <th>PTE Questions per task (w.e.f November 16, 2021)</th>
                    <th>Duration per task</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Summarize spoken text</td>
                    <td>1 – 2</td>
                    <td>60-90 seconds to retain 50-70 words, 10 minutes to write</td>
                </tr>
                <tr>
                    <td>Multiple choice, multiple answers</td>
                    <td>1 – 2</td>
                    <td>40-90 seconds for prompt</td>
                </tr>
                <tr>
                    <td>Fill in the blanks</td>
                    <td>2 – 3</td>
                    <td>30-60 seconds</td>
                </tr>
                <tr>
                    <td>Highlight correct summary</td>
                    <td>1 – 2</td>
                    <td>30-90 seconds</td>
                </tr>
                <tr>
                    <td>Multiple choice, single answer</td>
                    <td>1 – 2</td>
                    <td>30-60 seconds</td>
                </tr>
                <tr>
                    <td>Select missing word</td>
                    <td>1 – 2</td>
                    <td>20-70 seconds</td>
                </tr>
                <tr>
                    <td>Highlight incorrect word</td>
                    <td>2 – 3</td>
                    <td>15-50 seconds</td>
                </tr>
                <tr>
                    <td>Write from Dictation</td>
                    <td>3 – 4</td>
                    <td>3-5 seconds</td>
                </tr>
                <tr>
                    <td>Total questions</td>
                    <td>12 – 20</td>
                    <td>Time Allocation: 30 – 43 minutes</td>
                </tr>
            </tbody>
        </table>

        <h5>PTE Registration 2022</h5>
        <p>Candidates wanting to register for the PTE exam 2022 would be required to follow the steps mentioned below to successfully book their PTE exam slot. Candidates should note that for booking your PTE Academic online, you need a valid ID – a valid Indian passport. Candidates will also be required to show their valid ID on the day of the PTE exam or else they will not be allowed to enter the PTE Test centre.</p>

        <h5>PTE Test at Home</h5>
        <p>Launched by Pearson PLC Group – the conducting body of the PTE exam, PTE at Home testing is the PTE Academic Online Test that is being conducted from the safety of the homes of the PTE test takers. However, before you rush to book your PTE at Home testing, there are certain important factors that have to be kept in mind before you register for your online PTE at Home test.</p>

        <h5>PTE Exam Dates 2022</h5>
        <p>The PTE exam is conducted throughout the year at designated PTE test centres across the country. The PTE exam dates 2022 might vary from centre to centre. Students must search for the seats available on the Pearson PTE Academic website. Simply select the test centres closest to you, and click next. The website would prompt you to the calendar with the available dates.</p>

        <h2 className='text-danger'>FAQS ABOUT PTE</h2>

        <h6>What is the difference between IELTS and PTE?</h6>
        <p>Both IELTS and PTE are Standard English language tests that are taken by students who are appearing for admission to a university/college abroad. While most universities/colleges accept both IELTS and/or PTE Scores, however, there are times when a particular college/university could lean towards one exam over the other. While IELTS is a paper-based exam and scored on a scale of 1-9, PTE is a computer-based test scored on a scale of 10-90 points. To choose amongst IELTS vs PTE vs TOEFL: which exam is better, please read</p>

        <h6>Which is tougher IELTS or PTE?</h6>
        <p>Both PTE and IELTS follow a similar standard of testing students in the English Language. It is important for students to practice well to be able to score well in the exam. It is entirely on the English language experience of the student to understand the level of the exam. Candidates can check out the syllabus and exam pattern of both PTE and IELTS to understand the difference between the two.</p>

        <h6>How can I check my PTE Score?</h6>
        <p>Candidates who have successfully appeared for the PTE Academic exam can access their scores by assessing the online account they would have created during the time of PTE Registration. Candidates would receive an email notifying the availability of their results on their registered id from PTE. Candidates are required to log into their account and check their results</p>

        <h6>What is a good PTE Score?</h6>
        <p>The range for the overall PTE Score is between 10 and 90 points. There is no set good score for PTE. Every college/university would have a recommended PTE Score they expect students to achieve to be eligible to apply for admissions. Hence, candidates must ensure that they get the PTE Score that is required by the college/university. It is a rule of thumb that getting a higher score would open a plethora of opportunities for students looking to pursue education abroad. Read: Best Books and Resources for PTE</p>

        <h6>How many times can I appear for the PTE exam?</h6>
        <p>A lot of times, the PTE Score may not be to the liking of the student and he may decide to appear again. A student can appear for the PTE Academic exam as many times as he wishes, although he is required to wait until he has received the scores of his previous PTE test before applying for another.</p>

        <h6>What are the countries that accept PTE?</h6>
        <p>The PTE Academic exam is widely accepted across universities/colleges in Canada, Australia, the United Kingdom, and New Zealand. There are also a lot of educational institutions in countries apart from the ones mentioned earlier that accept PTE Scores for their admission process.</p>

        <h6>How do I do well in my PTE Exam?</h6>
        <p>Whether through self-study or with the help of extra classes, candidates are always advised to give in their best during the examination. Referring to official PTE Study Material, solving multiple PTE Practice Papers and revising the PTE Syllabus and being aware of the PTE Exam Pattern is a good start for candidates looking to excel in their PTE Exam.</p>



    </div>

    </>
  )
}

import React from "react";

export default function Tutorials(props: { title: string }) {
  return (
    <>
      <div className="mainsec" style={{ marginTop: "9rem" }}>
        <div className="section-head col-sm-12 mb-0">
          <h4>
            <span>Tutorials </span>
          </h4>
        </div>

        <div className="container">
          <div className="accordion" id="accordionExample">
            {/* ---------------- Canada -----------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Canada
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=c868b80e246301febcd84d9606ba460070e0dd5215a09dc9348a4441b2c10e31"
                  >
                    College Multihexa, canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=341d89140fb5167d8c0668166430e5c70aed977150fb244abe9ecf4626c535b0"
                  >
                    Northern Lights College, canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=d333d400a531aea7672db008f922208836ea6545bb6d7e660955ef08a236904f"
                  >
                    Canada training: P1
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=1368981dcd4bdc38db990a73001caa0fcb8a069e6836ee44079604fc4e77ba7f"
                  >
                    Canada Training P2
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=d9892f61fd38c46cdd56215dcbee5ac9b2273d9935cb7497bd1ed4c6eb393aa1"
                  >
                    Canada P2
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=0ebfa3b0852dc593be9b36697baf2b55d56742ab5e9632153981787ec7851139"
                  >
                    St.Clair College Training Session,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=8f4d0e59eb29f3050b20846cbbf70fe0ff3da0472ee600977784f00237d2c90a"
                  >
                    Bow Valley College(Center of Entertainment and Arts),Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=a9b2def0f83fef1bcd451da0e490f1354a90d062cdc6a2b1a8d7dbf2fb1dfd84"
                  >
                    Training : Bow Valley College , Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=52eec7a5080c0c34e53e8cf7034e0effcc81aac4e8b58e5489c5b31bbd779e57"
                  >
                    KEYANO COLLEGE,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=44ae4b7c5b296e5c3ca9916f57540316b4c24088a5557c69443417465105e13b"
                  >
                    Mac Ewan University ,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=930f2db6331358cdc1d2122396875508096458b386482413f669b7386e77424b"
                  >
                    St. Lawrence Collage Training Session,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=802ccc78ae3f6064207e3d604cf2754832930356c9c8927aa215928987654164"
                  >
                    Queens University Training Session,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=273b4fd03919e20346d14c138963383d2f1a189f2d65c8192ae4877316cc2d66"
                  >
                    Loyalist College, Toronto Training Session,Canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=43f7786abd82cfd65501ed3bfe90ad809ccbe8d1479d77b770a8c7a67bcc6fca"
                  >
                    Capilano University Training Session,canada
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=a5e04d498572abb2310cf67a47e0d1817cae7212073fdb5811e4b4358364a6f1"
                  >
                    UNBC Training Session,Canada
                  </a>
                </div>
              </div>
            </div>
            {/* ------------------- USA ----------------------- */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  USA
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=60d81d3d9d9ead71ff115c5314ebf99b6e095e16f4186421b44bb4335dc3ba05"
                  >
                    USA Training Session -1
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=5df6f7eef02938111ea460ac1b436aa98219783899bc9b7cbd562daec8c76180"
                  >
                    USA Training Session -2
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=d4fb7f63e0f62f0444bda29f4495c4539a4725174e4ba9ec0f8f914c4521a3d2"
                  >
                    USA Training Session -3
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=f1aa6261702685f71b33fd62731222aebd1afa7fe60fc004219c2af349d0b365"
                  >
                    USA Universities Training Session
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=ab6e2a8b1366dbabd4184e80a268593569c5e02b8383042ed305436c1d091b1d"
                  >
                    Study Group-USA Universities
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=49b531d861587dcdb6c5256433864c93dda3ce393cc2e687ad47d364d0180806"
                  >
                    Mercy College, San Francisco State University,USA
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=fa2eab1151c5b4a25d3004ea05385f64114d597a6ea2f9d9e8f10337d4b0ea05"
                  >
                    University of Louisville Training Session,USA
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=303556bea1a56def2652bcf2431b65dfb2592a12add5544d3f2927bbb4775bee"
                  >
                    Training: QS Quacquarelli Symonds, USA
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=16445179a1d235da51a5b6d0ed6c122a56775d7aed72afdaa80c067661a75716"
                  >
                    Training: Community College of Philadelphia, USA
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=5b7b9137224980a65b19963e3ce657b9356b8c2a17a14866e19408fa51351a20"
                  >
                    Training: Roger Williams University
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=ecfe2da426b685558be4144a907d762d770c7b5830ebe2b18ccac7252681b859"
                  >
                    Training : Oxford Brookes University
                  </a>
                </div>
              </div>
            </div>
            {/* ---------------------------- UK ---------------------- */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  UK
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=50144ff55cc79199bd84c7fdd7ad7e8978dae2996e8dc8b1f80072afe5b388a2"
                  >
                    Kaplan UK
                  </a>

                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=b277d18c7e5697d2a9f8e81f9f0527f1bff833e97feceb0cdabffe4c6de8eb3f"
                  >
                    University of Buckingham,UK
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=738c17709bc0312cf0d3572590e2a99e43d40878cbbff5fcc7057acc57aa02c9"
                  >
                    Istituto marangoni, UK, Paris,Italy
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=3e82623b67c8fcb5c260823eb1d6fca6f50e7ea619405ffb9437a470880062a9"
                  >
                    Training : Istituto Marangoni (Italy, France, London, UAE)
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=0db4b6efeda15b6341619d4eeaa368a70bb76b4ab71a39abb0339b7ddd6e480b"
                  >
                    Training : BIMM University, UK
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=9cf9548cf10f6c5bbcbcb764ae4580a3da0a9d0a9786907531b163fc77df086d"
                  >
                    Training : Abertay University
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=ecfe2da426b685558be4144a907d762d33023b2a869ccbd7f90ef4c98e7e06e8"
                  >
                    Training : London Metropolitan University
                  </a>
                </div>
              </div>
            </div>
            {/* -------------------------Australia ----------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Australia
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=dfaef64bc3e8ef0d11cf4b6a13d608dc3d4aecee541a865ff0c2fecc08ad7d31"
                  >
                    Educo Australia
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=cc40b8b92d1c1905870721ade640889278833e046b833bca945748a7a105a97c"
                  >
                    INTO USA,Australia
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=d28d72642169b73fed32bdf78a971e4edc27e8218ec3f6d526e8556cc3fc0dd0"
                  >
                    IIBIT - Global Higher Education
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=250d2428710dab0ce9fc68384b78619e575bc7410db2f97f258da8c0bd4be539"
                  >
                    Training : RGIT (Royal Greenhill Institute of Technology)
                  </a>
                </div>
              </div>
            </div>
            {/* --------------------------Europe ------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Europe
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=43bc881de4322fdf8ff6626fccbe40b4dc33201c8adf8cdb327595d1104921b7"
                  >
                    Europe Training Session
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=1ed41fc9204260f2cf9bc5ea68eba35a6c5a2865a4b3349c98345ce535506238"
                  >
                    Training : Learn Key Institute, Malta
                  </a>
                </div>
              </div>
            </div>
            {/* ---------------------------- Germany ------------------------- */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Germany
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=d474199fcf73882b3a73ad91b6b79e98138997650e8f7bccac297262c88b75a7"
                  >
                    Germany Training Part -2
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=5d7bc6f56518b38ec967d27b7bf9030c3988b7b22955d76bc77e71c6c7b72151"
                  >
                    Berlin School of Business and Innovation GmbH,Germany
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=250d2428710dab0ce9fc68384b78619e91ab4fedcf3f0883c6afc5b3e33879f6"
                  >
                    Training : University of Europe for Applied Sciences
                  </a>
                </div>
              </div>
            </div>
            {/* ------------------------- Canada Pre-Departure Session --------------------------  */}

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Canada Pre-Departure Session
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=1073639e121879da5b46e33a891661496f0c6a2f7853c5757f6fa5b68f0b6d61"
                  >
                    Canada Pre-Departure Session
                  </a>
                </div>
              </div>
            </div>

            {/* --------------- Training Session for St. George's University for Medical, West
                Indies, Grenada ---------------  */}

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Training Session for St. George's University for Medical, West
                  Indies, Grenada
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=6d9a5291a9c51c8fc87560babf94e0d7eae4bc90f8e243a923b04cf369b69a92"
                  >
                    Training Session for St. George's University for Medical,
                    West Indies, Grenada
                  </a>
                </div>
              </div>
            </div>
            {/* -------------- GRE Training Session: ----- */}

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingNine">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  GRE Training Session
                </button>
              </h2>
              <div
                id="collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=3105393fbfd4b0f2c1d0b2504b2b6812478930715fb879408f54a5cd1fed547f"
                  >
                    GRE Training Session
                  </a>
                </div>
              </div>
            </div>

            {/* ------------------------- Switzerland --------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEleven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  Switzerland
                </button>
              </h2>
              <div
                id="collapseEleven"
                className="accordion-collapse collapse"
                aria-labelledby="headingEleven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=77f53de750c0f82c5be4965ec91309022420d89773445caa04030220a5d85ac5"
                  >
                    HTMi Switzerland
                  </a>
                </div>
              </div>
            </div>
            {/* --------------------- New CRM process training by Gursharan Singh ---------- */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  New CRM process training by Gursharan Singh
                </button>
              </h2>
              <div
                id="collapseTen"
                className="accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/videoprv?recordingId=afd40fc65b845ebeb1c5b4c4c002e3e10ba4e2e4a69525108c87b626c5667171&x-meeting-org=679513303"
                  >
                    New CRM process training by Gursharan Singh
                  </a>
                </div>
              </div>
            </div>
            {/* -------------------  GBS Malta & Dubai Training Session ---------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwelwe">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelwe"
                  aria-expanded="false"
                  aria-controls="collapseTwelwe"
                >
                  GBS Malta & Dubai Training Session
                </button>
              </h2>
              <div
                id="collapseTwelwe"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwelwe"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=ce733ba1d0ae08e187d4b4876c1e23b7bef7405866c50450d9dd40b6da0bd477"
                  >
                    GBS Malta & Dubai Training Session
                  </a>
                </div>
              </div>
            </div>

            {/* -------------------------- MBBS Abroad ------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThirteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="false"
                  aria-controls="collapseThirteen"
                >
                  MBBS Abroad
                </button>
              </h2>
              <div
                id="collapseThirteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingThirteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=a06810c8ce3d0faaed16f4ac7794ff632604eee4a7980f18db1cd06fbd612f3a"
                  >
                    MBBS Abroad Training
                  </a>
                </div>
              </div>
            </div>

            {/* ---------------------------- France---------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFourteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  France
                </button>
              </h2>
              <div
                id="collapseFourteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingFourteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=3429ac07d84acfac809dd8374121ade4cb8b4a02bec73cd2332508304713a15d"
                  >
                    Training : Burgundy School of Business, France
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=bda039e08932da1bd69255a17c0d83ff51f6bbdfe02fc893a99d277835ba9193"
                  >
                    Training: Excelia Group, France
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=5a78f2fb6f0f8fb99baa130d52469c32e6922daac0e198647e8ec234d2374940"
                  >
                    Training : ICN Business School
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=506843469b63424d603d73bb2cebfe76192c84613f9d038a7b9e2c83821086d1"
                  >
                    Training : France as a study destination
                  </a>
                </div>
              </div>
            </div>
            {/* ----------------------- Sweden---------------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFifteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="false"
                  aria-controls="collapseFifteen"
                >
                  Sweden
                </button>
              </h2>
              <div
                id="collapseFifteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingFifteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=3cf5589ab74d3c0fa7f6b43ea9b734bf340a2747b766daafafe84f0606d607b3"
                  >
                    Training : Study in Sweden
                  </a>
                </div>
              </div>
            </div>
            {/* -------------------------------- Poland ------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSixteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesixteen"
                  aria-expanded="false"
                  aria-controls="collapsesixteen"
                >
                  Poland
                </button>
              </h2>
              <div
                id="collapsesixteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingsixteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=b7a70f8905b7a4ec58f2a5e8f6a14148b9cb7f6d5df721fab4508fa032e7c320"
                  >
                    Training : University of Economics and Human Sciences ,
                    Poland
                  </a>
                </div>
              </div>
            </div>
            {/* ----------------------------- Ireland -----------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingSeventeen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeventeen"
                  aria-expanded="false"
                  aria-controls="collapseSeventeen"
                >
                  Ireland
                </button>
              </h2>
              <div
                id="collapseSeventeen"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeventeen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=62b9c145f829a5562a0e537d191da68859b2eb02cf8ae9d6c69c1e1668a9a5a7"
                  >
                    Training : Dundalk Institute Of Technology, Ireland
                  </a>
                </div>
              </div>
            </div>
            {/* --------------------------------- Dubai ---------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingEighteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEighteen"
                  aria-expanded="false"
                  aria-controls="collapseEighteen"
                >
                  Dubai
                </button>
              </h2>
              <div
                id="collapseEighteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingEighteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=7e9dac25dfb8fdc1f691f537be559abf1c55243d54b51e4bee72b8a181e1c82d"
                  >
                    Training : UK College of Business and Computing (UKCBC),
                    Dubai
                  </a>
                </div>
              </div>
            </div>
            {/* -------------------- Latvia ----------------------------  */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingNinteen">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNinteen"
                  aria-expanded="false"
                  aria-controls="collapseNinteen"
                >
                  Latvia
                </button>
              </h2>
              <div
                id="collapseNinteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingNinteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=5c5110ea6e08b6cd0bc776be0468d56abaf1171373c40fdc29576298dba37ce2"
                  >
                    Training : Latvia University of Life Sciences and
                    Technologies
                  </a>
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=2c1be2f1a84a5839ba040320a7c703b86c208a5a52760a1478537b1816ae426c"
                  >
                    Latvia Training
                  </a>
                </div>
              </div>
            </div>

            {/* ------------------ Netherlands ----------------------   */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwenty">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwenty"
                  aria-expanded="false"
                  aria-controls="collapseTwenty"
                >
                  Netherlands
                </button>
              </h2>
              <div
                id="collapseTwenty"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwenty"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=c883f9bf95c7c9c72740ce7911bb92440a0aed9100d975d13efc09b7718b30c6"
                  >
                    Training : Netherlands as a study destination
                  </a>
                </div>
              </div>
            </div>

            {/* ------------------ Denmark ----------------------   */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwentyOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyOne"
                  aria-expanded="false"
                  aria-controls="collapseTwentyOne"
                >
                  Denmark
                </button>
              </h2>
              <div
                id="collapseTwentyOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentyOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/videoprv?recordingId=593a20d1b9ed8caba9297f98d2032b5545cb95ab302c4443a02754568004046f&x-meeting-org=679513303"
                  >
                    Training : Denmark as a study destination
                  </a>
                </div>
              </div>
            </div>
            {/* ------------------ Hungary ----------------------   */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwentyTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwentyTwo"
                >
                  Hungary
                </button>
              </h2>
              <div
                id="collapseTwentyTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentyTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <a
                    target="blank"
                    className="btn btn-danger shadow-sm rounded-3 mx-2 my-1"
                    href="https://meeting.zoho.com/meeting/public/videoprv?recordingId=146ead571da9cb0271eb016dd7a9140353291ec8fa88b92ce6d26f68cc2a0aca"
                  >
                    Training : Hungary as a study destination
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'

export default function Canadaprogram(props: { title: string; }) {
  return (
    <>
    <div className="container" style={{marginTop:'9rem'}}>
    <div className="section-head col-sm-12 mb-0">
          <h4><span>Canada Program</span> Availability</h4>
        </div>
    <iframe title='Canada Program' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQjIv8i3LDmzvcrJeHXu8i_RnE7HJ6l4cd1rRS2JRY1cXHQ5hdbIdiPdavuMz7wZlOI68hIqIpKmLtU/pubhtml?widget=true&amp;headers=false" width="100%" height="650px"></iframe>
    </div>
    </>
  )
}

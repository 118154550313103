import React from 'react'
import Ausintro from './Ausintro'
// import Visainfoaus from './Visainfoaus'
import ausbg from '../assets/img/ausbg.jpg'
import ausflag from '../assets/flags/Australia.png'

export default function Auspage() {
  return (
    <>
          {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Why Study In Australia ?</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Worldwide Recognition</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Academic Flexibility</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Funding Opportunities </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Research & Technology</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Global Opportunities </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> State of the Art Infrastructure</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> World Class Faculty</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4' style={{width:'80px'}} alt='flag'/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Why to Study in Australia ?</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container">
    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Australia</span></h4>
    </div>
    <Ausintro/>

    

    </div>



    </>
  )
}

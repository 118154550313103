import React from "react";
// import { Helmet } from 'react-helmet'
// import Homeleftsec from "./Homeleftsec";
import Homecentersec from "./Homecentersec";
// import Homerightsec from "./Homerightsec";

export default function Home(props: { title: string; }) {

  return (
    <>
 {/* <Helmet>
        <script src="https://salesiq.zoho.com/widget" defer />
        <script type="text/javascript">
          {`
            var $zoho=$zoho || {};
            $zoho.salesiq = $zoho.salesiq || {
              widgetcode:"f23d7bcfa6e1d2e2cb13c25d3a80a369831a43f879c6e7e4544bd7c13ea8c54b",
              values:{},
              ready:function(){}
            };
            var d=document;
            var s=d.createElement("script");
            s.type="text/javascript";
            s.id="zsiqscript";
            s.defer=true;
            s.src="https://salesiq.zoho.com/widget";
            var t=d.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(s,t);
          `}
        </script>
      </Helmet> */}

<div className="row mx-0" style={{marginTop:'8rem'}}>
  {/* <Homeleftsec/> */}
  <Homecentersec/>
  {/* <Homerightsec/> */}
</div>



    </>
  );
}

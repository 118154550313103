import React from 'react'

export default function Partner(props: { title: string; }) {
  return (
    <>
    <div className="container bg-light2 py-3" style={{marginTop:'9rem'}}>
    <div className="section-head col-sm-12 mb-0">
          <h4><span>Partner</span> Institutions</h4>
        </div>
    <iframe title='Partner' src="https://analytics.zoho.com/open-view/1855984000012570707" width="100%" height="850px"></iframe>
    </div>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/globedwiselogo.png'


export default function Mobileheadercopy() {
  
  return (
    <>
<div className="mobilenavsec">


   <nav className="navbar bg-light fixed-top shadow-none">
  <div className="container-fluid">
    <Link className="navbar-brand" to="/"><img src={logo} className='mx-0' alt="globedwise" style={{width:'150px'}}/></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
    <i className="fa fa-bars" aria-hidden="true"></i>
    </button>
    <div className="offcanvas offcanvas-end " tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
      <Link className="navbar-brand" to="/" data-bs-dismiss="offcanvas" aria-label="Close"><img src={logo} className='mx-0' alt="globedwise" style={{width:'150px'}}/></Link>        
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        
      <div className="mobile-head-body">
        <div className="mobileheadelinks">
        <h5 className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close"><Link to="/" className='text-primary fw-bold'>Home</Link></h5> 
        <h5 className='mb-3' data-bs-dismiss="offcanvas" aria-label="Close"><a className="fw-bold text-primary" target="blank" href="https://www.globedwise.com/events">Upcoming Events</a></h5>   
        <h5 className='mb-3' data-bs-dismiss="offcanvas" aria-label="Close"><Link to="/partner-institutions" className='text-primary fw-bold'>Partner Institutions</Link></h5>   
        <h5 className='mb-3' data-bs-dismiss="offcanvas" aria-label="Close"><Link to="/canada-program-availability" className='text-primary fw-bold'>Canada Program Availability</Link></h5>   
        <h5 className='mb-3' data-bs-dismiss="offcanvas" aria-label="Close"><Link to="/check-program-availability" className='text-primary fw-bold'>Check Program Availability</Link></h5>   
        <h5 className='mb-3' data-bs-dismiss="offcanvas" aria-label="Close"><Link to="/admission-evaluator-tool" className='text-primary fw-bold'>Admission Evaluator Tool</Link></h5>   
            

<div className="navcols d-flex flex-column">

<div className="btn-group my-2">
  <h5 className="text-primary dropdown-toggle" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
  More
  </h5>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item fw-bold" to="/">Shortlisting Tool</Link></li>
            
            <li className="py-1"><Link target='blank' className="dropdown-item fw-bold" to="https://globedwise.com/services/finbee">Finbee</Link></li>
            
            <li className="py-1"><Link className="dropdown-item fw-bold" to="/contact-information">Contact Information</Link></li>
                        
            <li><Link className="dropdown-item fw-bold" to="/tutorials">Tutorials</Link></li>
          </ul>
</div>

</div>

       
        
        </div>


        <div className="mobilesocialsec">
        <div className="socail1">
    <a className="customicon mx-2" href="https://twitter.com/globedwise" target="blank"><i className="fab fa-twitter"></i></a>
    <a className="customicon mx-2" href="https://www.linkedin.com/company/globedwise/" target="blank"><i className="fab fa-linkedin"></i></a>
    <a className="customicon mx-2" href="https://www.facebook.com/Globedwise/" target="blank"><i className="fab fa-facebook"></i></a>
    <a className="customicon mx-2" href="/" target="blank"><i className="fab fa-youtube"></i></a>
    <a className="customicon mx-2"href="https://www.instagram.com/globedwise_india/" target="blank"><i className="fab fa-instagram"></i></a>    
    </div>

    <div className="socialcall mx-2 my-3"> 
    <h5><a href="tel:919696875875" className="text-primary2"> <i className="fa fa-phone-alt text-danger" aria-hidden="true"></i> +91 9696875875 </a></h5>
    </div>
    <div className="socialcall mx-2 my-3"> 
    <h5><a href="mailto:info@globedwise.com" className="text-primary2"><i className="fa fa-envelope text-danger" aria-hidden="true"></i> info@globedwise.com </a></h5>
    </div>

        </div>

        </div>
       
      </div>
    </div>
  </div>
</nav>

</div>
    </>
  )
}
